<template>
  <div class="reward-list">
    <ul class="tight">
      <li v-for="(item, idx) in items" :key="idx">
        <Anchor :href="getLink(item, idx)" class="wrapper" :class="{'pointer border-hover-point': getLink(item, idx) || $lib.isPreviewPage()}" :click="click">
          <div class="relative">
            <span class="count">{{ $lib.getNumberFormat(item.joinCount) }}명 후원</span>
            <span class="count ml-1" v-if="item.rewardQty > 0 && item.extraCount > 0">{{ $lib.getNumberFormat(item.extraCount) }}개 남음</span>
            <div class="amount font-md bold">
              <span v-if="item.rewardSeq">{{ item.rewardAmt ? $lib.getNumberFormat(item.rewardAmt) + "원 펀딩" : "(N/A)" }}</span>
              <span v-else>리워드 없이 참여</span>
            </div>
            <div class="title">
              <span v-html="item.title" v-if="item.rewardSeq"></span>
              <span v-else>이 프로젝트를 후원합니다.</span>
            </div>
            <div class="delivery font-xs" v-if="item.rewardExpectText">배송 예정일 : {{ item.rewardExpectText }}</div>
            <template v-if="(!item.rewardSeq || Number(item.rewardQty) === 0 || item.extraCount > 0)">
              <div class="act" v-if="accessible">
                <a class="btn font-xs">
                  <span>후원하기</span>
                </a>
              </div>
            </template>
            <div class="alert alert-warning font-sm" v-else>리워드가 모두 펀딩되었습니다.</div>
          </div>
        </Anchor>
      </li>
    </ul>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Anchor from "@/components/Anchor";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "pageProjectDetailRewards";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  props: {
    project: Object,
    accessible: Boolean,
    items: Array
  },
  setup(props) {
    const component = new Component(() => {
    });

    const getLink = (item) => {
      if (!lib.isPreviewPage() && (!item.rewardSeq || Number(item.rewardQty) === 0 || item.extraCount > 0) && props.accessible) {
        return `/reward/${router.app.$route.params.projectSeq}/order?rewardSeq=${item.rewardSeq || ""}`;
      }

      return undefined;
    };

    const click = () => {
      lib.isPreviewPage() && store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
    };

    return {component, click, getLink,};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.reward-list {
  padding-top: $px20;

  ul {
    li {
      margin-bottom: $px20;

      .wrapper {
        text-decoration: none;
        display: block;
        padding: $px28 $px25;
        background: #fff;
        border-radius: $px4;
        border: $px1 solid #eee;
        transition: border-color 0.18s;

        .count {
          display: inline-block;
          font-size: 70%;
          background: $colorSecondary;
          color: #fff;
          padding: $px1 $px5;
          margin-bottom: $px5;
          vertical-align: middle;
          border-radius: $px2;

          span {
            vertical-align: middle;
          }
        }

        .amount {
          margin-bottom: $px10;
        }

        .delivery {
          color: #666;
          margin-top: $px15;
        }

        .act {
          position: absolute;
          top: 0;
          right: 0;

          a {
            border: $px1 solid $colorPoint;
            color: $colorPoint;
            font-weight: 500;
            padding: $px12 $px18;

            span {
              display: inline-block;
              vertical-align: middle;
            }
          }
        }

        .alert {
          margin: $px15 0 0 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media(max-width: 991px) {
    padding-top: $px15;

    ul li {
      margin-bottom: $px10;
    }
  }
}
</style>