<template>
  <div class="detail-investors" :class="{skeleton: !state.loaded}" ref="wrapRef">
    <div v-if="state.investors && state.investors.length">
      <ul class="people tight clearfix">
        <li v-for="(c, idx) in state.investors" :key="idx">
          <div class="wrapper">
            <div class="profile">
              <MemberPicture :memberSeq="c.memberSeq" size="35"/>
              <span class="name font-sm">{{ fundType === "02" ? "투자자" + (idx + 1) : c.memberName }}</span>
              <div class="date font-xs">{{ c.createDate }}</div>
            </div>
            <div class="amount color-point border-point" v-if="c.subscrAmount">
              <div>
                <span>₩{{ $lib.getNumberFormat(c.subscrAmount) }}</span>
              </div>
              <span class="text">투자 완료</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="page">
        <Pagination :info="state.pagination" :change="load" v-if="state.pagination"/>
      </div>
    </div>
    <div v-else class="part">
      <NoData/>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import MemberPicture from "@/components/MemberPicture.vue";
import Pagination from "@/components/Pagination.vue";
import Loading from "@/components/Loading.vue";
import mixin from "@/scripts/mixin";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageProjectDetailInvestors";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {NoData, MemberPicture, Pagination, Loading},
  props: {
    projectSeq: Number,
    projectType: String,
    fundType: String,
  },
  setup(props) {
    const component = new Component(() => {
      for (let i = 0; i < 5; i += 1) {
        state.investors.push({
          createDate: "0000-00-00 00:00",
          subscrAmount: 1000,
          memberName: "Wait a moment"
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      investors: [],
      pagination: {},
    });

    const wrapRef = ref();

    const load = (num) => {
      const args = {
        pageIndex: num ? num : 1,
        pageSize: 5,
        pageUnit: 5,
      };

      state.loaded = false;
      http.get(`/api/invest/projects/${props.projectSeq}/coop`, args).then(({data}) => {
        state.loaded = true;
        state.investors = data.body;
        state.pagination = data.body.paginationInfo;
      });

      if (num) {
        nextTick(() => {
          const rect = wrapRef.value.getBoundingClientRect();
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

          window.scrollTo({
            top: rect.top + scrollTop - 78,
            left: 0,
            behavior: "smooth"
          });
        });
      }
    };

    return {component, state, wrapRef, load};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-investors {
  .people {
    padding-top: $px20;
    margin: 0 $px-5;

    li {
      margin-bottom: $px15;
      border-radius: $px4;
      position: relative;
      padding: 0 $px5;
      float: left;
      width: calc(100% / 3 - 0.1px);

      .wrapper {
        border: $px1 solid #eee;
        min-height: $px150;
        background: #fff;
        padding: $px30;

        .profile {
          float: left;
          position: relative;
          padding-top: $px1;
          padding-left: $px45;

          .img {
            position: absolute;
            top: $px4;
            left: 0;
          }

          .name {
            font-weight: 500;
            margin-bottom: $px1;
          }

          .date {
            color: #767676;
          }
        }
      }

      .amount {
        position: absolute;
        top: $px30;
        right: $px30;
        border: $px1 solid $colorSecondary;
        text-align: center;
        padding: $px23 $px5;
        min-width: $px91;
        border-radius: $px4;
        height: $px91;

        span {
          margin: 0 $px1;
          font-size: $px14;
        }

        .text {
          margin-top: $px2;
          font-size: $px12;
        }
      }
    }
  }

  .page {
    margin-top: $px35;
  }

  &.skeleton {
    .people li .wrapper {
      .profile {
        .img, .name, .date {
          @include skeleton;
        }
      }

      .amount span {
        @include skeleton;
      }
    }
  }

  @media (max-width: 1200px) {
    .people li {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    .people li {
      width: 100%;
    }
  }
}
</style>