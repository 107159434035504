<template>
  <div class="detail" :class="{skeleton : !state.loaded }" :data-project-type="projectType" :data-tab="state.tab.list[state.tab.idx] ? state.tab.list[state.tab.idx].name : ''">
    <div class="wrapper">
      <div class="header">
        <div class="container">
          <!--          <router-link :to="`/${projectType}/list`" class="back font-sm pointer" title="프로젝트 목록으로 이동">-->
          <!--            <span class="img" style="background-image: url(/assets/ico/common.back.svg)"></span>-->
          <!--          </router-link>-->
          <router-link :to="state.project.projectCate ? `/${projectType}/list?category=${state.project.projectCate}` : ''" class="category btn no-underline font-xs" v-if="state.project.projectCate">
            {{ state.project.projectCateName }}
          </router-link>
          <div class="project-name">
            <span>{{ state.project.projectName || "(제목 없음)" }}</span>
            <span class="admin-info pointer" title="관리자용 프로젝트 정보" @click="openProjectDetailInfoForAdmin()" v-if="['MEMBER_MID_ADMIN', 'MEMBER_TOP_ADMIN'].includes($store.state.account.memberAuth)"></span>
          </div>
          <div class="tags">
            <ul class="tight" v-if="state.loaded">
              <li v-for="(t, idx) in state.project.tags" :key="idx">
                <router-link class="btn font-xs" :to="`/main/search?searchKeyword=${encodeURIComponent(t.replace('#', ''))}`">{{ t }}</router-link>
              </li>
            </ul>
            <ul class="tight" v-else>
              <li v-for="(i) in 7" :key="i">
                <a>Wait</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="summary">
        <div class="container">
          <div class="row">
            <div class="left col-lg-8">
              <div class="wrapper">
                <div class="video" v-if="state.project.videoFlag === 'Y'">
                  <div class="ratio43">
                    <iframe :src="state.project.videoUrl" title="유튜브 플레이어" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
                <div class="sliders" v-else>
                  <div class="slider represent swiper" ref="slider1Ref">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" v-for="(t, idx) in state.project.thumbnails" :key="idx">
                        <span class="img" :style="{backgroundImage: `url('${$store.getters.thumbnailUrl(t.thumbFilePath, {origin: state.project.originThumbnailYn === 'Y'})}')`, backgroundPosition: state.project.mainImagePosition}"></span>
                      </div>
                    </div>
                  </div>
                  <div class="slider thumbnails swiper-container" ref="slider2Ref"
                       :style="{maxWidth: `calc(100% - ${(state.project.thumbnails.length + 1) * swiperThumbnailSpaceBetween}px)`}"
                       v-if="state.project.thumbnails && state.project.thumbnails.length > 1">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" v-for="(t, idx) in state.project.thumbnails" :key="idx">
                        <span class="img" :style="{backgroundImage: `url('${$store.getters.thumbnailUrl(t.thumbFilePath)}')`}"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right col-lg-4">
              <div class="top">
                <div class="adult-only color-danger" v-if="state.project.adultFlag === 'Y'">
                  <i class="fa fa-exclamation-circle mr-1"></i>
                  <span>만 19세 이상 후원 가능한 프로젝트입니다.</span>
                </div>
                <div class="wrapper">
                  <div class="section amount font-base">
                    <div class="key clearfix">
                      <span>{{ projectType === "reward" ? "모인 금액" : "투자 금액" }}</span>
                      <!--                    <span class="pt-1 font-xs pull-right">목표 금액: {{ $lib.getNumberFormat(projectType === "reward" ? state.project.targetAmt : state.project.totAmount) }}원</span>-->
                    </div>
                    <div class="value">
                      <b v-if="state.mockFlag === 'Y' && state.contestInfo.projectStateHideYn === 'Y'">비공개</b>
                      <template v-else>
                        <b>{{ state.loaded ? $lib.getNumberFormat(projectType === "reward" ? state.project.expenseAmt : state.project.subscribeAmount) : "0000000" }}</b>
                        <span class="pl-1">{{ computedCurrencyUnit }} </span>
                        <span class="percent color-point">{{ $lib.getNumberFormat(projectType === "reward" ? state.project.per : state.project.subscribeRate) }}%</span>
                      </template>
                    </div>
                  </div>
                  <div class="section amount font-base" v-if="state.contestInfo.contestSeq === '745' && state.mockFlag === 'Y'">
                    <div class="key clearfix">
                      <span class="red">목표 금액</span>
                    </div>
                    <div class="value">
                      <b class="red">{{ $lib.getNumberFormat(state.project.targetAmt) }}</b>
                      <span class="pl-1 red">{{ computedCurrencyUnit }} </span>
                    </div>
                  </div>
                  <div class="section cheer font-base">
                    <div class="key">
                      <span>{{ projectType === "reward" ? "후원자" : "투자자" }}</span>
                    </div>
                    <div class="value">
                      <b v-if="state.mockFlag === 'Y' && state.contestInfo.projectStateHideYn === 'Y'">비공개</b>
                      <template v-else>
                        <b>{{ state.loaded ? $lib.getNumberFormat(projectType === "reward" ? state.project.investorCnt : state.project.subscribeCount) : "0000000" }}</b>
                        <span class="pl-1">명</span>
                      </template>
                    </div>
                  </div>
                  <div class="section dday font-base">
                    <div class="key">
                      <span>남은 기간</span>
                    </div>
                    <div class="value" v-if="projectType === 'reward'">
                      <template v-if="computedIsProgressing">
                        <template v-if="state.project.dday === '0'">
                          <b class="today">오늘 마감</b>
                        </template>
                        <template v-else>
                          <b>{{ $lib.getNumberFormat(state.project.dday || 0) }}</b>
                          <span class="pl-1">일</span>
                        </template>
                        <span class="end-date" v-if="state.project.fundingEndDate">({{ state.project.fundingEndDate }} 마감)</span>
                      </template>
                      <b class="finished" v-else>마감</b>
                    </div>
                    <div class="value" v-else>
                      <div class="finished font-lg" v-if="state.project.dDay === '0'">오늘 마감</div>
                      <template v-else>
                        <b>{{ state.project.dDay || "0" }}</b>
                        <span class="pl-1" v-if="$lib.isNumeric(state.project.dDay || 0)">일</span>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="guide" :style="{ paddingRight: projectType === 'reward' ? '' : '' }" v-if="state.mockFlag !== 'Y'">
                  <template v-if="projectType === 'reward'">
                    <div v-if="[0, 1].includes(Number(state.project.progressOrder))">
                      <span v-if="state.project.fundingType === 'K'">목표액 {{ $lib.getNumberFormat(state.project.targetAmt) }}원에 미달하여도 진행되는 프로젝트입니다. 프로젝트 후원 시 바로 결제가 진행됩니다.</span>
                      <span v-else-if="state.project.fundingType === 'A'">
                        <span v-if="state.project.successFailFlag === 'S'">목표액인 {{ $lib.getNumberFormat(state.project.targetAmt) }}원을 달성하였습니다.<br/>결제는 {{ state.project.payDate }}에 진행됩니다.</span>
                        <span v-else>목표액 {{ $lib.getNumberFormat(state.project.targetAmt) }}원에 미달하면 결제가 진행되지 않는 프로젝트입니다.<br/>결제는 목표액달성시 {{ state.project.payDate }}에 진행됩니다.</span>
                      </span>
                      <div class="preview" v-else>프로젝트가 대기 중입니다.</div>
                    </div>
                    <div v-else-if="state.project.progressOrder == 2">
                      <span v-if="state.project.successFailFlag === 'S'">후원자 {{ $lib.getNumberFormat(state.project.investorCnt) }}명의 관심과 응원으로 프로젝트가 목표액을 달성했습니다. 진행자에게 응원의 한마디를 전하세요.</span>
                      <span v-else>프로젝트가 마감되었습니다. 앞으로 많은 관심 부탁드립니다. 진행자분께 응원의 한마디를 전하세요.</span>
                    </div>
                    <!--                  <button class="btn btn-default font-xs" @click="goComment()">응원하기</button>-->
                  </template>
                  <div v-else>
                    <b class="color-point font-sm">
                      <template v-if="state.project.endFlag === 'N'">{{ state.project.fundingEndDate }} 마감</template>
                      <template v-else>마감되었습니다.</template>
                    </b>
                    <div class="notice">
                      <div class="">청약 가능 시간: 05시~23시</div>
                      <div v-if="state.project.fundType !== '02'">일반 투자자의 연간 투자 한도는 1,000만원,</div>
                      <div v-if="state.project.fundType !== '02'">기업당 투자 한도는 500만원입니다.</div>
                    </div>
                  </div>
                  <div class="funding-type" :class="{ pointer: !computedIsProgressing }" v-if="projectType === 'reward'" @click="!computedIsProgressing && goComment()">
                    <template v-if="computedIsProgressing">{{ state.project.fundingType === "A" ? "성공해야 리워드" : "무조건 리워드" }}</template>
                    <template v-else>응원<br/>하기</template>
                  </div>
                </div>
                <div class="mock-guide font-base" v-else>
                  <div class="font-sm mb-1">
                    <span>모의투자 한도: </span>
                    <b class="color-point">{{ $lib.getNumberFormat(state.contestInfo.projectPossibleLimit) }}{{ computedCurrencyUnit }}</b>
                  </div>
                  <div class="font-sm">
                    <span>모의투자한 금액: </span>
                    <b class="color-point">{{ $lib.getNumberFormat(state.contestInfo.myProjectInvestAmt) }}{{ computedCurrencyUnit }}</b>
                  </div>
                </div>
              </div>
              <div class="acts" :class="{ climb: state.climb}">
                <div class="row">
                  <div class="col-5">
                    <div class="row">
                      <div class="act up interest col-6 pr-0">
                        <button class="btn btn-block btn-default" :class="{ active: $store.state.interests.sequences[projectType].includes(projectSeq) }" @click="toggleInterest()" title="관심 프로젝트">
                          <span class="img"></span>
                          <div class="text">{{ $lib.getNumberFormat(state.project.interestCnt) }}</div>
                        </button>
                      </div>
                      <div class="act up share col-6 pr-0">
                        <button class="btn btn-block btn-default" title="공유하기" @click="openShareModal()">
                          <span class="img" style="background-image: url(/assets/ico/common.share.svg);"></span>
                          <div class="text">{{ state.project.share > 999 ? "999+" : state.project.share }}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="act col-7 join">
                    <ProjectDetailJoinBtn type="main" :loaded="state.loaded" :projectType="projectType" :progressOrder="Number(state.project.progressOrder)" :enter="enter" :invest="invest" :project="state.project"
                                          :mockFlag="state.mockFlag"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="alert warning font-sm" v-if="projectType === 'invest'">
            <span>
              <span>증권형 크라우드펀딩에 대한 투자는 투자원금의 손실이 발생할 수 있습니다. 투자에 대한 책임은 전적으로 투자자 본인에게 있으므로, 투자위험에 대한 내용을 꼭 확인해주세요. </span>
              <a class="pointer color-anchor" @click="$store.commit('openModal', { name: 'Info', params: { category: 'invest' }})">자세히보기</a>
            </span>
          </div>
          <div class="maker-info d-lg-none" v-if="state.mockFlag !== 'Y'">
            <MakerInfo
                :builderSeq="state.project.openId || state.project.memberSeq || state.project.builderSeq"
                :projectType="projectType"
            />
          </div>
        </div>
      </div>
      <div class="content" ref="contentRef" v-if="state.loaded">
        <div class="wrapper" ref="contentWrapperRef">
          <div :id="`${component.name}Tabs`" class="tabs" ref="tabsRef" :class="{fixed: state.fixed}" :style="{ top: state.tab.top }">
            <div class="container">
              <div class="wrapper" :class="projectType === 'invest' && 'invest'">
                <ul class="nav nav-tabs">
                  <li class="nav-item" :class="{hide: idx === 3}" v-for="(t, idx) in state.tab.list" :key="idx">
                    <a class="btn no-underline nav-link pointer" :class="{ 'active': idx === state.tab.idx }" @click="pushRouterTabQuery(t.name)">
                      <span>
                        <span class="title">{{ t.title }}</span>
                        <span class="count" v-if="t.count">{{ t.count > 99 ? "99+" : t.count.toString() }}</span>
                      </span>
                    </a>
                  </li>
                </ul>
                <ProjectDetailJoinBtn type="tab" :loaded="state.loaded" :projectType="projectType" :progressOrder="Number(state.project.progressOrder)" :enter="enter" :invest="invest" :project="state.project"
                                      :mockFlag="state.mockFlag" v-if="projectType === 'reward' ? Number(state.project.progressOrder) === 1 : state.project.endFlag !== 'Y'"/>
              </div>
            </div>
          </div>
          <div ref="scrollRef"></div>
          <div class="container">
            <div class="row">
              <div class="article" :class="projectType === 'invest' || state.mockFlag === 'Y' ? 'col-lg-12' : 'col-lg-8'">
                <template v-if="projectType === 'reward'">
                  <div class="intro wrapper" v-show="state.tab.idx === 0">
                    <DetailIntro :projectType="projectType" :projectSeq="projectSeq" :newEditorFlag="state.project.newEditorFlag" :simulationFlag="state.mockFlag" :rewardItems="state.rewardItems" :openId="state.project.openId"
                                 ref="detailIntroRef"/>
                  </div>
                  <div class="wrapper">
                    <DetailCommunity :projectType="projectType" :projectSeq="projectSeq" :qnaCount="state.qnaCount" :newsCount="state.newsCount" :reviewCount="state.project.reviewCount" :investRewards="state.project.investRewards"
                                     :investEndDate="state.project.investEndDate" :openId="state.project.openId"
                                     v-if="state.tab.idx === 1"/>
                    <DetailCheers :projectType="projectType" :projectSeq="projectSeq" :investorNameOpenYn="state.contestInfo ? state.contestInfo.investorNameOpenYn : ''"
                                  :investorNameAltText="state.contestInfo ? state.contestInfo.investorNameAltText : ''" :currencyUnit="computedCurrencyUnit"
                                  v-else-if="state.tab.idx === 2"/>
                    <DetailGuide v-else-if="state.tab.idx === 3"/>
                    <DetailCoop :projectSeq="projectSeq" v-else-if="state.tab.idx === 4"/>
                  </div>
                </template>
                <template v-else>
                  <div class="wrapper">
                    <DetailInvestorRelations :project="state.project" :projectType="projectType" :projectSeq="projectSeq" :fixed="state.fixed" v-if="state.tab.idx === 0"/>
                    <DetailCommunity :projectType="projectType" :projectSeq="projectSeq" :qnaCount="state.qnaCount" :newsCount="state.newsCount" v-if="state.tab.idx === 1"
                                     :openId="state.project.memberSeq"/>
                    <DetailInvestors :projectSeq="projectSeq" :fundType="state.project.fundType" v-if="state.tab.idx === 2"/>
                  </div>
                </template>
              </div>
              <div class="rewards col-lg-4" v-if="state.rewardItems.length && state.mockFlag !== 'Y'">
                <div class="wrapper">
                  <div class="maker-info d-none d-lg-block">
                    <MakerInfo
                        :builderSeq="state.project.openId || state.project.memberSeq || state.project.builderSeq"
                        :projectType="projectType"
                    />
                  </div>
                  <DetailRewards :project="state.project" :accessible="computedIsProgressing" :items="state.rewardItems" ref="rewardsRef" v-if="state.project.projectSeq"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relatives" v-if="state.loaded && projectType === 'reward'">
        <DetailRelatives :projectSeq="projectSeq" :projectCate="state.project.projectCate" :simulationFlag="state.mockFlag"/>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref, watch} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import router from "@/scripts/router";
import store from "@/scripts/store";
import Stick from "@/components/Stick";
import DetailIntro from "./DetailIntro";
import DetailRewards from "./DetailRewards";
import DetailCommunity from "./DetailCommunity";
import DetailCheers from "./DetailCheers";
import DetailGuide from "./DetailGuide";
import DetailInvestorRelations from "./DetailInvestorRelations";
import DetailInvestors from "./DetailInvestors";
import DetailRelatives from "./DetailRelatives";
import DetailCoop from "./DetailCoop";
import MemberPicture from "@/components/MemberPicture";
import Loading from "@/components/Loading";
import ProjectDetailJoinBtn from "@/components/ProjectDetailJoinBtn";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";
import {useGtm} from "@gtm-support/vue2-gtm";
import track from "@/scripts/track";
import Anchor from "@/components/Anchor";
import redirectCallback from "@/scripts/redirectCallback";
import MakerInfo from "@/components/MakerInfo";

function Component(initialize) {
  this.name = "pageProjectDetail";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {
    MakerInfo,
    Anchor,
    Loading,
    Stick,
    DetailIntro,
    DetailRewards,
    DetailCommunity,
    DetailCheers,
    DetailGuide,
    DetailInvestorRelations,
    DetailInvestors,
    DetailRelatives,
    DetailCoop,
    MemberPicture,
    ProjectDetailJoinBtn,
  },
  props: {
    projectType: String,
  },
  setup(props) {
    const component = new Component(async () => {
      if (router.app.$route.path.includes("/changeHistory") && !store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      state.loaded = false;
      const args = lib.isPreviewPage() ? {preview: "Y"} : undefined;

      const res1 = await http.get(`/api/${props.projectType}/projects/${projectSeq}`, args, {cache: true}).catch(httpError((err) => {
        switch (err?.response?.status) {
          case 401:
            return store.dispatch("moveBack", `/${props.projectType}/list`);

          case 403:
            return store.dispatch("moveBack", `/${props.projectType}/list`);

          case 404:
            return router.replace("/error/404");
        }
      }));

      state.loaded = true;

      if (res1.data.code === "301 MOVED_PERMANENTLY") {
        window.alert("해당 프로젝트가 아직 오픈되지 않아 오픈예정 페이지로 이동합니다.");

        if (res1.data.body) {
          return router.replace(res1.data.body);
        }

        return router.replace("/error/404");
      }

      if (res1?.error) {
        return;
      }

      state.mockFlag = res1.data.body.mockFlag;
      state.project = res1.data.body.project;
      state.newsCount = res1.data.body.newsCount;
      state.qnaCount = res1.data.body.qnaCount;

      if (state.mockFlag === "Y") {
        state.contestInfo = res1.data.body.contestInfo;
      }

      if (state.project.subscribeAmount && typeof state.project.subscribeAmount !== "number") {
        state.project.subscribeAmount = window.Number(state.project.subscribeAmount.replaceAll(",", ""));
      }

      if (state.project.homepage) {
        state.project.homepage = state.project.homepage.replaceAll(" ", "").trim();
      }

      await store.dispatch("setDocumentTitle", state.project.projectName);

      if (props.projectType === "invest") {
        store.state.account.loggedIn && res1.data.body.isExistNotConfirmedChange && store.commit("confirm", {
          message: "본 프로젝트는 투자 판단에 큰 영향을 미칠 수 있는 중요사항이 변경되었습니다. 청약 기간 내에 투자 의사를 재확인하시지 않으실 경우 투자는 자동 취소됩니다. 확인 버튼을 통해 상세 내용 및 투자 의사를 확인해주세요.",
          allow() {
            router.push({path: `/invest/${projectSeq}/changeHistory/${res1.data.body.historySeq}`});
          },
          disallow() {
            router.push({path: `/${props.projectType}/list`});
          }
        });
      } else {
        const args = lib.isPreviewPage() ? {preview: "Y"} : undefined;

        if (state.project.fundingType === "A") {
          // AON의 경우 결제일은 종료일 + 2일
          const investEndDate = lib.getDate(state.project.investEndDate);
          investEndDate.setDate(investEndDate.getDate() + 2);
          state.project.payDate = lib.getDateFormat(investEndDate, "yyyy-MM-dd");
        } else {
          state.project.payDate = state.project.investEndDate;
        }

        state.loaded = false;
        const res2 = await http.get(`/api/reward/projects/${projectSeq}/rewards`, args, {cache: true}).catch(httpError());
        state.loaded = true;

        if (res2?.error) {
          return;
        }

        state.loaded = true;
        state.rewardItems = res2.data.body;
      }

      if (props.projectType === "invest") {
        state.tab.list.push(...[
          {name: "ir", title: "온라인 IR", count: null},
          {name: "news", title: "커뮤니티", count: state.newsCount + state.qnaCount},
          {name: "investors", title: "투자자", count: state.project.subscribeCount},
        ]);
      } else {
        state.tab.list.push(...[
          {name: "intro", title: "프로젝트 소개", count: null},
          {name: "news", title: "커뮤니티", count: state.newsCount + state.qnaCount + state.project.reviewCount},
          {name: "cheer", title: state.mockFlag === "Y" ? "투자자" : "후원자", count: state.project.investorCnt},
          {name: "reward", title: "리워드 안내", count: null},
        ]);

        if (state.project?.crowdYn === "Y") {
          state.tab.list.push({name: "cooperation", title: "협업 지원", count: null});
        }
      }

      if (Array.isArray(res1.data.body.thumbNailList)) {
        state.project.thumbnails = res1.data.body.thumbNailList;
        // state.project.thumbnails.forEach(e => { e.thumbFilePath = encodeURI(e.thumbFilePath)});
        // console.log(definitions.urls.imgCdn);
        // state.project.thumbnails.forEach(e => { console.log(e.thumbFilePath)})
      }

      // amounts.myContestAvailableAmt = state.contestInfo.investPossibleLimit - state.contestInfo.myContestInvestAmt;
      // amounts.myProjectAvailableAmt = state.contestInfo.projectPossibleLimit - state.contestInfo.myProjectInvestAmt;
      //
      // if (amounts.myContestAvailableAmt >= amounts.myProjectAvailableAmt) {
      //   amounts.myAvailableAmt = amounts.myProjectAvailableAmt;
      // } else {
      //   amounts.myAvailableAmt = amounts.myContestAvailableAmt;
      // }

      let tagIdx = 0;
      state.project.tags = [];

      for (let t of lib.getSplit(state.project.tag, ["\r\n", "\r", "\n", ","])) {
        t && t.trim() && state.project.tags.push(t.replaceAll("#", "").trim());

        if (++tagIdx === 5) {
          break;
        }
      }

      nextTick(() => {
        if (state.project.thumbnails && state.project.thumbnails.length > 1) {
          const swiper = new window.Swiper(slider2Ref.value, {
            spaceBetween: swiperThumbnailSpaceBetween,
            slidesPerView: state.project.thumbnails ? state.project.thumbnails.length : "auto",
            freeMode: true,
            watchSlidesProgress: true,
            allowTouchMove: false,
          });

          new window.Swiper(slider1Ref.value, {
            spaceBetween: 10,
            effect: "fade",
            thumbs: {swiper},
          });
        }

        setTab();
      });

      track.post({
        name: `${props.projectType}ProjectDetail`,
        category: props.projectType === "invest" ? "투자하기" : state.mockFlag === "Y" ? "모의투자" : "후원하기",
        topic: "상세 조회",
        title: state.project.projectName,
      });

      // GTM으로 이벤트 전달
      const loggingMsg = `Event ${props.projectType}Project Detail`;

      try {
        const gtm = useGtm();
        if (gtm.enabled()) {
          // console.log(loggingMsg);
          let dataLayerProjectName = state.project.projectName;
          let dataLayerEvent = "EventRewardProjectDetail";
          // 후원형인 경우 첫번째 리워드의 금액을 상품금액으로 전달
          let dataLayerAmount = (state.rewardItems.length > 0 ? state.rewardItems[0].rewardAmt : 0);
          if (props.projectType === "invest") {
            dataLayerEvent = "EventInvestProjectDetail";
            // 증권형인 경우 최소투자금액 * 최소투자단위를 상품금액으로 전달
            dataLayerAmount = state.project.minAmount * state.project.minCount;
          }
          window.dataLayer?.push({
            event: dataLayerEvent,
            "project.projectSeq": projectSeq,
            "project.projectName": dataLayerProjectName,
            "amount": dataLayerAmount,
          });
        }
      } catch (err) {
        // 데이터 전송이 실패하면 콘솔로그출력
        console.warn(`Failed ${loggingMsg} - ${err}`);
      }
    });

    const state = reactive({
      interestProject: null,
      mockFlag: "N",
      contestInfo: {
        contestSeq: "0",
        projectPossibleLimit: 0,
        currencyUnit: "",
        projectStateHideYn: "N",
        investorNameAltText: "",
        investorNameOpenYn: "",
      },
      project: {
        projectCate: "Wait",
        projectCateName: "Wait",
        projectName: "Wait a moment",
        targetAmt: 0,
        expenseAmt: 0,
        subscribeAmount: 0,
        per: "0",
        subscribeRate: "0",
        investorCnt: 0,
        thumbnails: [],
        builderName: "Wait a moment",
        corporateName: "Wait a moment",
        fundType: "",
        fundingType: "Wait a moment",
        endFlag: "",
        displayStateName: "Wait a moment",
        totAmount: 0,
        payDate: "",
        originThumbnailYn: "N",
        progressOrder: 0,
        mainImagePosition: 0,
      },
      climb: false,
      rewardItems: [],
      loaded: false,
      fixed: false,
      newsCount: null,
      qnaCount: null,
      tab: {
        idx: 0,
        list: [],
        top: "0",
      },
    });

    const slider1Ref = ref();
    const slider2Ref = ref();
    const contentRef = ref();
    const contentWrapperRef = ref();
    const tabsRef = ref();
    const detailIntroRef = ref();
    const rewardsRef = ref();
    const scrollRef = ref();

    const computedCurrencyUnit = computed(() => {
      return state.contestInfo.currencyUnit || "원";
    });

    const computedIsProgressing = computed(() => {
      return Number(state.project.progressOrder) === 1 || lib.isPreviewPage();
    });

    const projectSeq = Number(router.app.$route.params.projectSeq);
    const swiperThumbnailSpaceBetween = 5;

    const pushRouterTabQuery = (name) => {
      if (!["intro", "cooperation"].includes(name) && lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      const query = {};

      if (name !== "intro") {
        query.tab = name;
      }

      if (JSON.stringify(query) === JSON.stringify(router.app.$route.query)) {
        return;
      }

      const paths = [props.projectType];
      lib.isPreviewPage() && paths.push("preview");
      paths.push(projectSeq.toString());
      router.push({path: `/${paths.join("/")}`, query});
    };

    const goComment = () => {
      if (lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      select(0);
      pushRouterTabQuery("intro");
      nextTick(detailIntroRef.value.goComment);
    };

    const enter = () => {
      if (lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      if (props.projectType === "reward") {
        if (state.mockFlag === "Y") {
          return router.push({path: `/mock/${projectSeq}/order`});
        } else if (!state.rewardItems.length) {
          return store.commit("setSwingMessage", "리워드가 없습니다. 관리자에게 문의해주세요.");
        }

        return store.commit("openModal", {
          name: "Rewards",
          params: {
            projectName: state.project.projectName,
            items: state.rewardItems
          }
        });
      } else {
        router.push({path: `/invest/${projectSeq}/order`}).then();
      }
    };

    const increaseShareCount = () => {
      http.put(`/api/${props.projectType}/projects/${projectSeq}/share`).then(() => {
        state.project.share = window.Number(state.project.share) + 1;
      });
    };

    const openShareModal = () => {
      const paths = [props.projectType, projectSeq];
      lib.isPreviewPage() && paths.splice(1, 0, "preview");

      store.commit("openModal", {
        name: "Share",
        params: {
          path: `/${paths.join("/")}`,
          title: state.project.projectName,
          desc: state.project.simpleText,
          img: {
            url: store.getters.thumbnailUrl(state.project.thumbnails[0]?.thumbFilePath, {share: true}),
            width: definitions.thumbnail.width,
            height: definitions.thumbnail.height
          }
        },
        callback: `${component.name}.increaseShareCount`
      });
    };

    const openProjectDetailInfoForAdmin = () => {
      store.commit("openModal", {
        name: "ProjectDetailForAdmin",
        params: {
          project: state.project,
          projectType: props.projectType
        }
      });
    };

    const select = (idx) => {
      if (!contentRef.value) {
        return;
      } else if (lib.isPreviewPage() && !["intro", "cooperation", "ir"].includes(state.tab.list[idx].name)) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      state.tab.idx = idx;
      // const contentOffsetTop = contentRef.value.offsetTop + parseFloat(window.getComputedStyle(contentRef.value).paddingTop);
      // const curScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //
      // if (scrollTo || curScrollTop > contentOffsetTop) {
      //   nextTick(() => {
      //     window.scrollTo({
      //       top: contentOffsetTop,
      //       left: 0,
      //       behavior: "smooth"
      //     });
      //   });
      // }
    };

    const toggleInterest = () => {
      if (lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      const args = {
        projectSeq,
        projectType: props.projectType,
        projectName: state.project.projectName
      };

      if (!store.state.account.loggedIn) {
        return redirectCallback.setAndLogin("toggleProjectInterest", {...args, refresh: true});
      }

      redirectCallback.run("toggleProjectInterest", {
        ...args,
        onSuccess(include, has) {
          if (!include) {
            state.project.interestCnt = Number(state.project.interestCnt) - 1;
          } else if (!has) {
            state.project.interestCnt = Number(state.project.interestCnt) + 1;
          }
        }
      });
    };

    const openProfileModal = () => {
      if (!state.loaded) {
        return;
      }

      store.commit("openModal", {
        name: "Profile",
        params: {
          memberName: props.projectType === "invest" ? state.project.corporateName : state.project.builderName,
          memberSeq: props.projectType === "invest" ? state.project.memberSeq : state.project.openId,
          memberIntro: props.projectType === "invest" ? state.project.introduction : state.project.builderIntroduction,
          projectType: props.projectType,
          projectSeq: projectSeq,
          email: props.projectType === "invest" ? state.project.managerEmail : state.project.builderEmail,
          homepage: props.projectType === "invest" ? state.project.homepage : "",
          phoneNum: props.projectType === "invest" ? state.project.businessTel : "",
          imgPath: state.project.builderImageUrl,
        }
      });
    };

    const onScroll = () => {
      if (!contentWrapperRef.value) {
        return;
      }

      const top = contentWrapperRef.value.getBoundingClientRect().top - lib.getHeaderHeight();
      state.fixed = top <= 0;

      if (state.fixed) {
        state.tab.top = lib.getHeaderHeight() + "px";
      } else {
        state.tab.top = "0";
      }
    };

    const invest = (param) => {
      switch (param) {
        case "confirm":
          return store.commit("confirm", {
            message: "해당 프로젝트는 청약금액을 달성하여 종료 후 배정을 못 받을 수 있습니다. 진행하시겠습니까?",
            allow: enter,
          });

        case "finished":
          return store.commit("setSwingMessage", "마감된 프로젝트입니다. 감사합니다.");

        case "preparing":
          return store.commit("setSwingMessage", "아직 펀딩이 시작되지 않았습니다.");
      }
    };

    const setTab = () => {
      const tab = router.app.$route.query.tab || router.app.$route.params.tab;

      switch (tab) {
        case "news":
        case "qna":
        case "review":
          select(1);
          break;

        case "cheer":
        case "investors":
          select(2);
          break;

        case "contributors":
          select(3);
          break;

        case "cooperation":
          select(4);
          break;

        default:
          select(0);
          break;
      }
    };

    watch(() => router.app.$route.query, (next, prev) => {
      if (next.modals || prev.modals) {
        return;
      }

      setTab();

      if (!state.fixed) {
        return;
      }

      window.scrollTo(0, 0);

      setTimeout(() => {
        const wrapperPaddingTop = window.Number(lib.getComputedStyle(contentWrapperRef.value, "paddingTop")?.replace("px", ""));
        const gap = wrapperPaddingTop || 60;
        window.scrollTo(0, scrollRef.value.getBoundingClientRect().top - lib.getHeaderHeight() - gap);
      });
    });

    onMounted(() => {
      store.commit("addComponentClass", {to: "ground", value: "mobile-no-padding-top"});
      store.commit("addComponentClass", {to: "header", value: "mobile-no-fixed"});
      store.commit("addComponentClass", {to: "footer", value: "mobile-margin-bottom"});

      store.commit("addListener", [component.name, "onScroll", () => {
        state.climb = lib.isClimbable();
        onScroll();
      }]);
    });

    onUnmounted(() => {
      store.commit("removeComponentClass", {to: "ground", value: "mobile-no-padding-top"});
      store.commit("removeComponentClass", {to: "header", value: "mobile-no-fixed"});
      store.commit("removeComponentClass", {to: "footer", value: "mobile-margin-bottom"});
    });

    return {
      component,
      state,
      slider1Ref,
      slider2Ref,
      contentRef,
      contentWrapperRef,
      tabsRef,
      detailIntroRef,
      rewardsRef,
      scrollRef,
      projectSeq,
      swiperThumbnailSpaceBetween,
      computedCurrencyUnit,
      computedIsProgressing,
      increaseShareCount,
      openShareModal,
      openProjectDetailInfoForAdmin,
      select,
      setTab,
      pushRouterTabQuery,
      goComment,
      enter,
      toggleInterest,
      openProfileModal,
      invest,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail {
  > .wrapper {
    padding-top: $px39;

    > .header {
      text-align: center;

      .back {
        float: left;
        margin-right: $px-130;
        text-decoration: none;

        .img {
          margin-right: $px7;
          width: $px16;
          height: $px16;
          vertical-align: middle;
        }

        span {
          vertical-align: middle;
        }

        &:hover {
          span {
            font-weight: 500;
          }
        }
      }

      .category {
        background: $colorBackground;
        color: #666;
        padding: $px6 $px11;
        border: 0;
        height: $px30;

        &:hover {
          background-color: #ddd;
        }
      }

      .project-name {
        text-align: center;
        font-weight: 500;
        margin-top: $px20;
        font-size: $px30;

        .admin-info {
          background: url("/assets/ico/page.project.detail.info.svg") center center no-repeat;
          background-size: $px24;
          display: inline-block;
          margin-left: $px10;
          vertical-align: middle;
          height: $px30;
          width: $px30;
        }
      }

      .tags {
        margin-top: $px20;

        ul {
          min-height: $px43;

          li {
            display: inline-block;
            margin: $px5;

            a {
              display: inline-block;
              padding: 0 $px8;
              height: $px33;
              line-height: $px31;
              border: $px1 solid #eee;
              border-radius: $px20;
              text-decoration: none;
              font-size: 0.85rem;
              color: #666;

              &:hover {
                border-color: #ddd;
                background-color: $colorBackground;
              }
            }
          }
        }
      }
    }

    > .summary {
      padding: $px30 0;

      > .container {
        > .row {
          .left {
            > .wrapper {
              max-width: $px600;
              margin: 0 auto;

              .video {
                iframe {
                  width: 100%;
                  height: 100%;
                }
              }

              .sliders {
                position: relative;
                overflow: hidden;
                padding-top: $ratio43Percent;
                outline: 1px solid #eee;

                .slider {
                  position: absolute;

                  .swiper-wrapper {
                    .swiper-slide {
                      background-color: #fff;
                      height: 100%;

                      .img {
                        width: 100%;
                        height: 100%;
                        background-size: auto 100%;
                        background-position: center center;
                      }

                      &:last-child {
                        margin-right: 0 !important;
                      }
                    }
                  }

                  &.represent {
                    outline: $px1 solid #eee;
                    background: $colorBackground;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    .swiper-wrapper {
                      .swiper-slide {
                        //max-height: $px450;
                      }
                    }
                  }

                  &.thumbnails {
                    font-size: 0;
                    right: $px15;
                    bottom: $px15;
                    height: auto;
                    width: $px420;
                    opacity: 0.8;

                    .swiper-wrapper {
                      justify-content: flex-end;
                      align-items: flex-end;
                      height: auto;

                      .swiper-slide {
                        overflow: hidden;
                        border: $px1 solid #ddd;
                        background-color: #fff;
                        height: auto;
                        max-width: calc(100% / 5);

                        .img {
                          width: 100%;
                          height: auto;
                          padding-top: $ratio43Percent;
                        }

                        &.swiper-slide-thumb-active {
                          border: $px2 solid $colorPoint;
                        }
                      }
                    }

                    &:hover {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: $px10;

            .top {
              .adult-only {
                display: inline-block;
                margin-bottom: $px10;
                font-size: $px13;
              }

              .wrapper {
                font-size: 0;

                .section {
                  margin-bottom: $px15;
                  position: relative;

                  .alert {
                    text-align: center;
                  }

                  .key {
                    font-size: $px14;
                  }

                  .value {
                    height: $px44;

                    > b {
                      font-size: $px29;

                      &.finished {
                        font-size: $px28;
                      }
                    }

                    > span {
                      display: inline-block;
                    }
                  }

                  &.dday {
                    margin-bottom: $px20;

                    .value {
                      .finished {
                        padding-top: $px8;
                        font-weight: 500;
                        letter-spacing: 0;
                      }
                    }
                  }

                  &.amount {
                    margin-bottom: 0;

                    .key {
                      .total-amount {
                        display: inline-block;
                        margin-left: 3px;
                      }
                    }

                    .value {
                      margin-bottom: $px20;

                      b {
                        font-weight: 500;
                      }

                      .percent {
                        font-size: $px14;
                        margin-left: $px8;
                      }
                    }
                  }

                  .red {
                    color: red;
                  }
                }
              }

              .guide {
                padding: $px15 $px20;
                background: $colorBackground;
                border: $px1 solid #eee;
                border-radius: $px4;
                font-size: 0.8rem;
                position: relative;
                word-break: keep-all;
                height: $px108;

                .preview {
                  padding-left: $px5;
                }

                > div {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  padding-right: $px15;

                  > b {
                    display: block;
                  }

                  .notice {
                  }

                  > span {
                    > span {
                      letter-spacing: $px-1;
                      word-break: keep-all;
                    }
                  }

                  > div:first-child {
                    margin-top: $px-1;
                  }
                }

                > .btn {
                  height: $px40;
                  padding: $px5;
                  border-color: #e3e3e3;
                  background: none;
                  margin-top: $px15;
                  width: 100%;

                  &:hover {
                    background: #eee;
                  }
                }

                .funding-type {
                  position: absolute;
                  font-size: $px12;
                  top: 50%;
                  left: $px15;
                  transform: translateY(-50%);
                  text-align: center;
                  background: #fff;
                  color: $colorPoint;
                  padding: $px12 0;
                  width: $px60;
                  height: $px60;
                  border-radius: 50%;
                }
              }

              .mock-guide {
                background: $colorBackground;
                padding: $px18;
                border: $px1 solid #eee;
                //margin-bottom: $px16;
                border-radius: $px4;
              }
            }

            .acts {
              height: $px72;
              //margin-top: $px21;

              > .row {
                height: 100%;

                > div {
                  height: 100%;

                  .act {
                    height: 100%;

                    &.up {
                      .btn {
                        height: 100%;
                        padding: $px3 0;
                        border: $px1 solid #eee;
                        width: 100%;
                        overflow: hidden;

                        .img {
                          height: $px20;
                          width: $px20;
                          vertical-align: middle;
                          position: relative;
                          top: $px-1;
                          right: $px-1;
                        }

                        .text {
                          position: relative;
                          left: 0.5px;
                          font-size: $px12;
                        }
                      }

                      &.interest {
                        button {
                          .img {
                            opacity: 1;
                            background-image: url(/assets/ico/page.detail.main.interest.svg);
                            transform: rotate(0);
                            transition: opacity 0.75s;
                          }

                          &.active {
                            .img {
                              background-image: url(/assets/ico/page.detail.main.interest.active.svg);
                            }
                          }
                        }
                      }

                      &.share {
                        .btn {
                          box-shadow: none;
                        }

                        .dropdown {
                          .dropdown-menu {
                            top: 100% !important;
                            padding: $px5 0 !important;
                            transform: none !important;
                            margin: $px-1 0 0 0;
                            width: calc(100% - 1px);
                            border-radius: 0 0 $px4 $px4;
                            border-color: #eee;

                            .dropdown-item {
                              padding: $px8 $px15;

                              .img {
                                width: $px20;
                                height: $px20;
                                margin-right: $px6;
                                vertical-align: middle;
                              }

                              .txt {
                                vertical-align: middle;
                                position: relative;
                                top: $px-1;
                              }
                            }
                          }

                          &.show {
                            .btn {
                              border-radius: $px4 $px4 0 0;
                            }
                          }
                        }
                      }
                    }

                    &.join {
                      width: 100%;

                      .btn {
                        border: 0;
                        position: relative;
                        height: $px69;
                        width: 100%;
                        opacity: 1;

                        .txt {
                          display: inline-block;
                          margin-right: $px13;
                          vertical-align: middle;
                          font-size: $px20;
                        }

                        .img {
                          height: $px25;
                          width: $px25;
                          vertical-align: middle;
                        }

                        &.finished {
                          cursor: default;
                        }
                      }
                    }

                    &.up {
                      button {
                        &:hover {
                          background: #eee;
                        }
                      }
                    }
                  }

                  > .row {
                    height: 100%;
                  }
                }
              }
            }
          }
        }

        > .maker-info {
          margin: 15px 0 0px 0;
        }

        > .alert {
          padding: $px20;

          &.warning {
            margin-top: $px20;
            margin-bottom: 0;
            background: $colorBackground;
          }
        }
      }
    }

    > .content {
      border-top: $px1 solid #eee;
      border-bottom: $px1 solid #eee;
      background: $colorBackground;
      padding: $px10 0 $px70 0;
      min-height: 550px;

      > .wrapper {
        position: relative;
        padding-top: $px50;

        > .tabs {
          border-radius: $px4;
          display: block;
          background: $colorBackground;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          //padding: $px5 0;
          z-index: 2;

          > .container > .wrapper {
            position: relative;

            ul {
              border: 0;
              white-space: nowrap;
              overflow: auto;
              display: block;

              li {
                display: inline-block;
                text-align: center;
                margin-right: $px10;

                a {
                  padding: $px10 $px10 0 $px10;
                  border-radius: 0;
                  border: 0;
                  margin: 0;
                  font-size: $px14;
                  background: none;
                  box-shadow: none;

                  > span {
                    display: inline-block;
                    position: relative;
                    padding-bottom: $px10;

                    span {
                      vertical-align: middle;

                      &.count {
                        background: $colorSecondary;
                        color: #fff;
                        height: $px17;
                        padding: 0 $px4;
                        display: inline-block;
                        line-height: $px16;
                        font-size: $px10;
                        border-radius: $px2;
                        margin-left: $px5;
                        letter-spacing: $px1;
                      }
                    }
                  }

                  &.active {
                    color: $colorPoint;

                    > span {
                      > .title {
                        font-weight: 500;
                      }

                      > .count {
                        background: $colorPoint;
                      }
                    }
                  }

                  &:hover {
                    .title {
                      font-weight: 500;
                    }
                  }
                }

                &:first-child a {
                  border-radius: $px4 0 0 $px4;
                  border-left: 0;
                }

                &:last-child a {
                  border-radius: 0 $px4 $px4 0;
                }
              }
            }

            > .project-detail-join-btn {
              display: none;
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              width: calc(100% / 12 * 4 - 20px);

              .txt {
                display: inline-block;
                margin-right: $px10;
                vertical-align: middle;
              }

              .img {
                height: $px20;
                width: $px20;
                vertical-align: middle;
              }
            }

            &.invest {
              > .project-detail-join-btn {
                width: calc(100% / 12 * 3 - 20px);
              }
            }
          }

          &.fixed {
            position: fixed;
            top: $px93;
            left: 0;
            border-bottom: $px1 solid #eee;

            > .container > .wrapper {
              padding-top: $px10;
              padding-bottom: $px10;

              > .project-detail-join-btn {
                display: inline-block;
              }
            }
          }
        }

        .article {
          > .wrapper {
            border-top: 0;
            border-radius: $px4;

            &.intro {
              .reservation {
                border: $px1 solid #eee;
                background: #fff;
                padding: $px10;
                margin-top: $px20;
              }
            }
          }
        }

        .rewards {
          > .wrapper {
            border-top: 0;

            > .maker-info {
              margin-top: $px13;
            }
          }
        }
      }
    }

    .relatives {
      padding: $px30 0 $px15 0;
    }
  }

  &[data-project-type=reward] {
    > .wrapper > .summary > .container > .row .right .top .guide {
      padding-left: $px89;
      margin-top: $px20;
      //margin-bottom: $px20;
    }
  }

  @media (min-width: 1200px) {
    > .wrapper > .summary > .container > .row .right .top {
      .mock-guide {
        //margin-bottom: $px44;
      }
    }
  }

  @media (min-width: 992px) {
    > .wrapper {
      > .content .article.col-lg-8 {
        padding-right: $px3;
      }
    }
  }

  @media (max-width: 1199px) {
    > .wrapper > .summary .left > .wrapper .sliders {
      height: auto;
    }
  }

  @media (max-width: 991px) {
    > .wrapper {
      padding-top: $px25;

      > .header .tags {
        margin-top: $px10;

        ul li {
          margin: $px3;
        }
      }

      > .summary {
        > .container {
          > .row {
            .left {
              > .wrapper {
                .sliders {
                  .slider.thumbnails {
                    .swiper-wrapper {
                      .swiper-slide {
                        max-width: $px48;
                      }
                    }
                  }
                }
              }
            }

            .right {
              .top {
                .adult-only {
                  font-size: $px12;
                  margin-top: $px10;
                }

                .wrapper {
                  display: flex;
                  justify-content: space-between;
                  margin-top: $px30;

                  .section {
                    &.amount {
                      width: auto;
                      min-width: $px100;

                      .value {
                        position: relative;
                        height: auto;

                        .percent {
                          position: absolute;
                          top: $px-19;
                          left: $px55;
                          font-size: $px12;
                          font-weight: 500;
                        }

                        .increase {
                          padding-top: $px3;
                          position: static;
                        }
                      }
                    }
                  }
                }

                .guide {
                  margin-bottom: 0;
                }
              }

              .acts {
                padding: $px5;
                border-top: $px1 solid #eee;
                background: #fff;
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                height: $px60;
                transition: padding-right 0.18s;
                z-index: 5;

                .act {
                  &.up.share {
                    margin-left: $px-7;
                  }

                  &.join {
                    padding-left: 0;
                  }
                }

                &.climb {
                  padding-right: $px61;
                }
              }
            }
          }

          > .alert.warning {
            margin-top: $px15;
          }
        }
      }

      > .content {
        padding-bottom: $px40;

        > .wrapper {
          > .tabs {
            > .container {
              > .wrapper {
                ul li {
                  margin-right: 0;
                }
              }
            }

            &.fixed {
              top: 0;

              > .container {
                padding-right: $px10;
                padding-left: $px10;

                > .wrapper {
                  padding-top: $px3;
                  padding-bottom: $px3;

                  > .project-detail-join-btn {
                    display: none;
                  }
                }
              }
            }
          }

          .article {
            margin-bottom: 0;
          }
        }
      }
    }

    &:not([data-tab=intro]) {
      > .wrapper {
        > .content > .wrapper .rewards {
          display: none;
        }

        .relatives {
          display: none;
        }
      }
    }

    &[data-project-type=reward] {
      > .wrapper > .summary > .container > .row .right .top .guide {
        height: $px90;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[data-project-type=invest] {
      > .wrapper > .summary > .container > .row .right .top .guide {
        height: auto;

        > div {
          position: static;
          transform: none;
          padding-right: 0;
        }
      }
    }
  }

  &.skeleton {
    .adult-only {
      @include skeleton;
    }

    > .wrapper {
      .header {
        .back {
          @include skeleton;

          .img {
            visibility: hidden;
          }
        }

        .category {
          @include skeleton;
          color: transparent;
          cursor: default;
        }

        .project-name span {
          @include skeleton;
          color: transparent;
        }

        .tags ul li a {
          @include skeleton;
          color: transparent;
          border-color: transparent;
        }
      }

      > .summary {
        .alert {
          @include skeleton;

          a {
            color: inherit !important;
          }
        }

        .left {
          .wrapper {
            .sliders .slider .swiper-wrapper .swiper-slide .img {
              display: none;
            }

            .sliders .slider {
              border-color: transparent;

              .swiper-wrapper > .swiper-slide {
                @include skeleton;

                > img {
                  visibility: hidden;
                }
              }
            }
          }
        }

        .right {
          .top {
            .wrapper {
              .section {
                .alert {
                  @include skeleton;
                }

                .key > span {
                  @include skeleton;
                  color: transparent;
                }

                .value {
                  > b, > span {
                    @include skeleton;
                    color: transparent;
                  }
                }
              }
            }

            .guide {
              @include skeleton;
              border: 0;
              color: transparent;

              b {
                @include skeleton;
              }

              button {
                display: none;
              }

              .funding-type {
                visibility: hidden;
              }
            }

            .mock-guide {
              @include skeleton;

              span, b {
                @include skeleton;
              }
            }
          }

          .acts .act::v-deep {
            .btn {
              @include skeleton;

              .img {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    > .wrapper {
      .header {
        .project-name {
          font-size: $px20;
          padding: 0 $px20;
          word-break: keep-all;
          margin-top: $px10;
        }
      }

      > .summary {
        padding-top: $px15;
        padding-bottom: $px15;

        > .container {
          > .row {
            .right {
              .top {
                .wrapper {
                  margin-top: $px20;

                  .section {
                    .key {
                      font-size: $px13;
                    }

                    .value {
                      height: auto;

                      > b {
                        font-size: $px24;

                        &.finished {
                          font-size: $px24;
                        }

                        &.today {
                          font-size: $px16;
                          display: inline-block;
                          margin-top: $px4;
                        }
                      }

                      > span {
                        font-size: $px11;
                      }
                    }

                    &.amount {
                      padding-left: $px5;

                      .value {
                        margin-bottom: $px20;

                        .percent {
                          left: $px50;
                        }
                      }
                    }

                    &.cheer {
                      margin-bottom: $px20;
                    }

                    &.dday {
                      margin-bottom: $px20;
                    }
                  }
                }

                .guide {
                  > div > b {
                    margin-bottom: $px5;
                  }
                }
              }
            }
          }

          .alert.warning {
            padding: $px7 $px10;
          }
        }
      }

      > .content > .wrapper {
        padding-top: $px40;

        > .tabs {
          padding: 0;
        }
      }
    }
  }
}
</style>