export default {
  step3: [{
    title: "프로젝트 정보",
    name: "fundingInfo",
  }, {
    title: "템플릿 선택",
    name: "fundingIntroTemplate",
    guide: "템플릿 별 예시보기"
  }, {
    title: "프로젝트 소개",
    name: "fundingIntro",
  }, {
    title: "프로젝트 포인트",
    name: "fundingPoint",
  }, {
    title: "리워드 소개",
    name: "rewardIntro",
    desc: "리워드가 한 개 이상이라면 아래 리워드 추가를 눌러 소개글을 모두 작성해주세요.",
  }, {
    title: "리워드 구성",
    name: "rewardConstructor",
    hideExample: true,
  }, {
    title: "요약 정보",
    name: "summery",
    desc: "프로젝트의 요약 정보를 작성해주세요.",
    hideExample: true,
  }, {
    title: "후원금 사용 계획",
    name: "plan",
  }, {
    title: "진행자 소개",
    name: "makerIntro",
  }]
};