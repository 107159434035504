<template>
  <div class="detail-intro-story">
    <div class="story">
      <div class="title" v-if="step.name !== 'fundingIntro'">
        <div class="inner">{{ step.title }}</div>
      </div>
      <div class="wrapper" v-for="(s, idx) in stories" :key="idx">
        <div class="images" v-if="s.files && s.files.length">
          <img :src="f.filePath + f.fileSaveName" :alt="s.title + (idx + 1)" v-for="(f, idx) in s.files" :key="idx"/>
        </div>
        <div class="points" v-if="step.name === 'fundingPoint'">
          <p class="point">
            <span>{{ idx + 1 }}.</span>
            <span>{{ s.content }}</span>
          </p>
        </div>
        <div class="reward" v-else-if="step.name === 'rewardConstructor'">
          <span class="title">{{ s.title }}</span>
          <div class="price">
            <span>{{ lib.getNumberFormat(s.rewardAmt) }} 원</span>
          </div>
          <div class="bottom">
            <div class="wrapper" v-if="s.rewardOptionItems.length">
              <b>옵션</b>
              <div class="options">
                <span v-for="o in s.rewardOptionItems" :key="o.rewardOptionSeq">{{ o.rewardOptionText }}</span>
              </div>
            </div>
            <div class="wrapper">
              <b>배송</b>
              <span>{{ s.rewardExpectText }}</span>
            </div>
          </div>
        </div>
        <template v-else>
          <div class="title">{{ s.title }}</div>
          <div class="content" v-html="s.content"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import DetailIntroComments from "./DetailIntroComments.vue";
import mixin from "@/scripts/mixin";
import lib from "../../scripts/lib";

function Component(initialize) {
  this.name = "pageProjectDetailIntroStory";
  this.initialize = initialize;
}

export default defineComponent({
  computed: {
    lib() {
      return lib;
    }
  },
  mixins: [mixin],
  components: {DetailIntroComments},
  props: {
    step: {
      title: String
    },
    stories: Array,
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  },
});
</script>

<style lang="scss" scoped>
.detail-intro-story {
  > .story {
    > .title {
      font-weight: 300;
      margin-bottom: $px25;
      border-bottom: $px1 solid #ddd;

      .inner {
        display: inline-block;
        font-size: $px18;
        background: #3c3c3c;
        border-radius: $px16 $px16 0 0;
        color: #fff;
        padding: $px5 $px30;
      }
    }

    > .wrapper {
      .images {
        img {
          width: 100%;
          margin-bottom: $px16;

          &:last-child {
            margin-bottom: $px36;
          }
        }
      }

      .points {
        .point {
          display: flex;

          > span {
            &:first-child {
              width: $px24;
            }
          }
        }
      }

      .reward {
        display: flex;
        flex-direction: column;
        gap: $px4;
        padding-bottom: $px12;

        > .title {
          word-break: break-word;
          font-size: $px16;
        }

        .price span {
          font-weight: 600;
          font-size: $px20;
          word-break: break-word;
        }

        > .bottom {
          display: flex;
          flex-direction: column;
          gap: $px8;
          margin-top: $px8;
          width: 100%;
          font-size: $px13;
          color: $colorSecondary;

          > .wrapper {
            word-break: break-word;
            display: flex;
            gap: $px12;

            b, span {
              font-size: $px14;
            }

            > b {
              flex-shrink: 0;
              display: flex;
              gap: $px8;
            }

            .options {
              span {
                display: block;
              }
            }
          }
        }
      }

      > .title {
        font-size: $px21;
        font-weight: 700;
        margin-bottom: $px32;
      }

      &:not(:last-child) {
        margin-bottom: $px26;
      }
    }
  }

  @media (max-width: 767px) {
    > .story {
      > .title {
        margin-bottom: $px12;

        .inner {
          font-size: $px16;
        }
      }

      > .wrapper {
        > .title {
          font-size: $px19;
        }

        .reward {
          > .title {
            font-size: $px16;
          }
        }
      }
    }
  }
}
</style>