<template>
  <div class="detail-relatives part">
    <div class="container">
      <div class="title">{{ title || "연관 프로젝트" }}</div>
      <div class="content">
        <div class="cards">
          <ul class="tight clearfix" v-if="state.relatives.length">
            <li v-for="(r, idx) in state.relatives" :key="idx">
              <Card
                  page="home"
                  projectType="reward"
                  :projectSeq="r.projectSeq"
                  :link="`/reward/${r.projectSeq}`"
                  :thumbFilePath="r.filePath + r.fileSaveName"
                  :amount="r.expenseAmt"
                  :projectName="r.projectName"
                  :progressOrder="r.progressOrder"
                  :projectCate="r.projectCateName"
                  :fundingType="r.fundingType"
                  :count="r.investorCount"
                  :percent="r.per"
                  :dday="Number(r.dday)"
                  :builderSeq="r.openId"
                  :builderName="r.builderName"
                  :builderImageUrl="r.builderFilePath"
                  :interest="true"
                  :mainImagePosition="r.mainImagePosition"
              />
            </li>
          </ul>
          <div class="pt-50 pb-50" v-else-if="state.loaded">
            <NoData/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import Card from "@/components/Card.vue";
import NoData from "@/components/NoData";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageProjectDetailRelatives";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    title: String,
    projectSeq: Number,
    projectCate: String,
    simulationFlag: String,
  },
  components: {NoData, Card},
  setup(props) {
    const component = new Component(() => {
      const args = {
        simulationFlag: props.simulationFlag || "N",
        projectCate: props.projectCate,
      };

      state.relatives = [];
      state.loaded = false;
      http.get(`/api/reward/projects/${props.projectSeq}/relation-projects`, args).then(({data}) => {
        state.loaded = true;

        for (let r of data.body) {
          r.filePath = r.filePath || "";
          r.fileSaveName = r.fileSaveName || "";
          state.relatives.push(r);
        }

        state.relatives.length > 4 && state.relatives.splice(4);
      });
    });

    const state = reactive({
      loaded: false,
      relatives: [],
    });

    return {component, state};
  }
});
</script>
<style lang="scss" scoped>
.detail-relatives {
  .title {
    font-weight: 500;
    margin-bottom: $px5;
  }

  .content {
    .cards {
      ul {
        margin: 0 $px-15 $px15 $px-15;

        li {
          float: left;
          width: calc(100% / 4);
          padding: $px15;
        }
      }
    }
  }

  @media(max-width: 767px) {
    .title {
      margin-bottom: $px15;
    }

    .content .cards {
      ul {
        margin: 0 $px-7;

        li {
          width: calc(100% / 2);
          padding: 0 $px8 $px18 $px8;
        }
      }
    }
  }
}
</style>