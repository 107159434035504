<template>
  <div class="detail-ir edit-style stock" :class="{fixed}" ref="wrapRef">
    <div class="row" v-if="state.loaded">
      <div class="article col-lg-9">
        <div class="part condition" v-if="state.tabs.map(t => t.name).includes('issuanceCondition')">
          <div class="wrapper">
            <div class="title">증권발행조건</div>
            <ul class="main tight">
              <li v-for="(c, idx) in state.issuanceConditions.mains" :key="idx">
                <div class="wrapper">
                  <div class="subject">{{ c.title }}</div>
                  <b class="value">{{ c.content }}</b>
                  <span class="tail" v-if="c.tail">({{ c.tail }})</span>
                </div>
              </li>
            </ul>
            <div class="sides clearfix" v-if="state.issuanceConditions.sides.length">
              <div class="side" v-for="(items, idx1) in state.issuanceConditions.sides" :key="idx1">
                <ul class="tight clearfix" v-if="items.length">
                  <template v-for="(i, idx2) in items">
                    <li v-if="i.title" :key="idx2">
                      <div class="wrapper">
                        <div class="subject">{{ i.title }}</div>
                        <b class="value font-sans">{{ i.content }}</b>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="part base" v-if="state.tabs.map(t => t.name).includes('info')">
          <div class="wrapper">
            <div class="title">기본정보</div>
            <div class="row">
              <div class="col-lg-3">
                <ul class="tight">
                  <li>
                    <dl>
                      <dt>기업명</dt>
                      <dd>{{ project.corporateName }}</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>대표명</dt>
                      <dd>{{ project.ceoName }}</dd>
                    </dl>
                  </li>
                  <li v-if="project.majorBusinessText">
                    <dl>
                      <dt>주요사업</dt>
                      <dd>{{ project.majorBusinessText }}</dd>
                    </dl>
                  </li>
                  <li v-if="project.staffNumber">
                    <dl>
                      <dt>임직원수</dt>
                      <dd>{{ project.staffNumber }}</dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <div class="col-lg-9">
                <ul class="tight">
                  <li v-if="project.corpEstablished">
                    <dl>
                      <dt>설립 일자</dt>
                      <dd>{{ project.corpEstablished }}</dd>
                    </dl>
                  </li>
                  <li v-if="project.homepage">
                    <dl>
                      <dt>웹 사이트</dt>
                      <dd>
                        <a :href="project.homepage" target="_blank" rel="noopener noreferrer">{{ project.homepage }}</a>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>소재지</dt>
                      <dd>
                        {{ project.businessAddr1 }}
                        {{ project.businessAddr2 }}
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>전화번호</dt>
                      <dd>{{ $lib.getPhoneNumberFormat(project.businessTel, true) }}</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="part point" v-if="state.tabs.map(t => t.name).includes('point')">
          <div class="wrapper">
            <div class="title">투자포인트</div>
            <div class="content">
              <dl class="font-base" v-for="(p, idx) in state.investPoints" :key="idx">
                <dt>{{ p.title }}</dt>
                <dd>{{ p.content }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="part income-deduction" v-if="state.tabs.map(t => t.name).includes('incomeDeduction')">
          <div class="wrapper">
            <div class="title">소득공제 혜택</div>
            <div class="content">
              <div class="calc">
                <div class="row">
                  <div class="col-6 col-lg-3">
                    <label :for="`${component.name}AnnualIncome`" class="font-sm">연 소득</label>
                    <Number :id="`${component.name}AnnualIncome`" placeholder="과세 표준으로 기입" :value.sync="state.calc.annualIncome" :maxlength="12"/>
                  </div>
                  <div class="col-6 col-lg-3">
                    <label :for="`${component.name}InvestAmount`" class="font-sm">투자 금액</label>
                    <Number :id="`${component.name}InvestAmount`" placeholder="투자 금액 기입" :value.sync="state.calc.investAmount" :maxlength="12"/>
                  </div>
                  <div class="col-6 col-lg-3 pt-15 pt-lg-0">
                    <label class="font-sm">예상 금액</label>
                    <input type="text" class="form-control border-focus-point font-sm" placeholder="계산하기 버튼 클릭" :value="state.calc.expectedAmount" disabled/>
                  </div>
                  <div class="col-6 col-lg-3 pt-15 pt-lg-0">
                    <label class="font-sm">&nbsp;</label>
                    <button class="btn btn-point btn-block font-sm" @click="calc()">계산하기</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="part intro" v-for="(i, idx) in state.investIntro" :key="idx">
          <div class="wrapper">
            <div class="title">{{ i.title }}</div>
            <div class="content" v-html="i.content" :id="`${component.name}Content`"></div>
          </div>
        </div>
        <div class="part reward" v-if="state.tabs.map(t => t.name).includes('reward')">
          <div class="wrapper">
            <div class="title">리워드혜택</div>
            <div class="content">
              <ul class="tight">
                <li v-for="(r, idx) in state.rewards" :key="idx">
                  <div class="amt">{{ $lib.getNumberFormat(r.rewardStartAmt) }}원 이상</div>
                  <div class="title font-xl bold">{{ r.title }}</div>
                  <div class="detail">{{ r.detailText }}</div>
                  <div class="cnt">
                    <span class="color-anchor">{{ r.investorCnt }}명</span>
                    <span> 참여</span>
                  </div>
                  <div class="remain">
                    <span v-if="Number(r.rewardQty) === 0">무조건 제공</span>
                    <span v-else-if="r.rewardQty <= r.investorCnt">0개 남음</span>
                    <span v-else>{{ r.rewardQty - r.investorCnt }}개 남음</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="part corp" v-if="state.tabs.map(t => t.name).includes('corp')">
          <div class="wrapper">
            <div class="title">기업 소개</div>
            <div class="content">
              <div class="history">
                <div class="subject font-base">회사 연혁</div>
                <table class="table">
                  <tbody>
                  <tr v-for="(h, idx) in state.companyHistory" :key="idx">
                    <th class="title">{{ h.title }}</th>
                    <td class="subtext">{{ h.subText }}</td>
                    <td class="content">{{ h.content }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="news" v-if="state.companyNews && state.companyNews.contentList.length">
                <div class="subject">관련 뉴스</div>
                <ul class="tight">
                  <li v-for="(n, idx) in state.companyNews.contentList" :key="idx">
                    <div class="title">
                      <a class="bold" :href="n.url" target="_blank" rel="noopener noreferrer">{{ n.title }}</a>
                    </div>
                    <div class="subtext">{{ n.subText }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="part file" v-if="state.tabs.map(t => t.name).includes('file')">
          <div class="wrapper">
            <div class="title">첨부자료</div>
            <div class="content">
              <div class="sm-title font-base">필수자료</div>
              <a class="download-all btn btn-point font-sm" @click="downloadAll()">전체 다운로드</a>
              <ul class="tight">
                <li v-for="(f, idx) in state.files.requires" :key="idx">
                  <div class="file-item">
                    <span>{{ f.fileTitle }}</span>
                    <div class="action-buttons">
                      <a class="font-sm btn btn-light" v-if="f.fileOrigName && ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf'].includes(getFileExtension(f.fileOrigName).toLowerCase())"
                         @click="previewFile(f.fileSeq)"
                         target="_blank" title="클릭하여 미리보기">
                        <i class="fa fa-eye"></i>
                      </a>
                      <a class="font-sm btn btn-light" @click="download(f.fileSeq)" title="클릭하여 다운로드">
                        <i class="fa fa-download"></i>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="content">
              <div class="sm-title font-base">참고자료</div>
              <ul class="tight">
                <li v-for="(f, idx) in state.files.references" :key="idx">
                  <div class="file-item">
                    <span>{{ f.fileTitle }}</span>
                    <div class="action-buttons">
                      <a class="font-sm btn btn-light" v-if="f.fileOrigName && ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf'].includes(getFileExtension(f.fileOrigName).toLowerCase())"
                         @click="previewFile(f.fileSeq)"
                         target="_blank" title="클릭하여 미리보기">
                        <i class="fa fa-eye"></i>
                      </a>
                      <a class="font-sm btn btn-light" @click="download(f.fileSeq)" title="클릭하여 다운로드">
                        <i class="fa fa-download"></i>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="part file" v-if="state.tabs.map(t => t.name).includes('closingData')">
          <div class="wrapper">
            <div class="title">공시자료</div>
            <div class="content">
              <ul class="tight">
                <li v-for="(f1, idx1) in state.files.closingDataList" :key="idx1">
                  <div class="sm-title">{{ idx1 }}년 공시자료</div>
                  <ul class="tight">
                    <li v-for="(f2, idx2) in f1.files" :key="idx2">
                      <a class="font-sm btn btn-light" @click="download(f2.fileSeq)">
                        <span>{{ f2.fileOrigName }}</span>
                        <i class="fa fa-download"></i>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="part warning" v-if="state.tabs.map(t => t.name).includes('warn')">
          <div class="wrapper">
            <div class="title">투자 위험 고지</div>
            <div class="content font-sm">
              <div class="summary">
                본 투자위험고지서는 [자본시장과 금융투자업에 관한 법률(이하
                "자본시장법")] 제117조의7 제4항에 의거하여 주식회사
                오마이컴퍼니(이하 ‘(주)오마이컴퍼니’)가 인터넷 홈페이지를 통하여
                온라인소액투자중개하는 금융투자상품에 대한 투자에 따르는 위험을
                귀하가 사전에 충분히 인지할 수 있도록 귀하에게 교부하는 것입니다.
              </div>
              <div>
                1. 귀하가 청약하고자 하는 금융투자상품은 자본시장법에 따른
                "증권"에 해당하므로 원본손실의 위험성이 있습니다. 또한 발행인이
                제시한 예상수익이나, 귀하가 기대하는 수익의 일부 또는 전부를 얻지
                못할 수 있습니다.
                <br/><br/>
                2. 귀하는 (주)오마이컴퍼니의 홈페이지에 게재(정정)된 모집되는
                증권의 발행조건, 발행인의 재무상태가 기재된 서류 및 사업계획서의
                내용, 증권의 취득에 따른 투자위험요소 등 모집게재사항을 충분히
                확인하고, 청약의 주문 시 (주)오마이컴퍼니가 별도로 정한 방법에
                의하여 통지한 투자 위험 등의 내용에 대하여 주의 깊게 확인하여야
                합니다.
                <br/><br/>
                3. (주)오마이컴퍼니는 자본시장법 제117조의11에 따라, 투자자 보호를
                위하여 투자 중개에 앞서 발행인 및 그 증권에 관한 주요 정보의 사실
                여부를 확인합니다.
                <br/><br/>
                다만, 게재된 투자모집정보에 관한 사항은 게재정보의 최신성 유지를
                위해 수정 혹은 삭제 및 추가될 수 있습니다. 변경사항에 대해서는
                당사의 홈페이지에 공지하며, 청약기간의 말일로부터 7일 이내에 수정
                혹은 삭제 및 추가사항이 있을 경우에는 투자자가 변경된 정보를
                토대로 충분한 의사판단을 할 수 있도록 해당일로부터 7일 후로
                청약기간을 연장합니다. 따라서 투자자는 자신이 이미 청약을
                완료하였다고 하더라도 모집이 종료되기 전까지는 당사의 홈페이지를
                통해 모집정보에 대한 지속적인 확인을 해야 합니다.
                <br/><br/>
                4. 모집성공 후 "예탁"되어지는 증권의 경우, 귀하의 증권계좌번호와
                계좌유효성이 반드시 확인되어야 합니다. 증권계좌번호와 계좌유효성이
                확인되어지지 않은 청약자는 모집성공 이후라도 청약이 취소될 수
                있습니다. 투자 완료 후 홈페이지의 ‘마이페이지 - 참여프로젝트 -
                증권형’ 페이지로 가면 자신의 투자 내역을 확인할 수 있으며,
                증권계좌 정보의 수정이 가능합니다.
                <br/><br/>
                5. 청약의 철회(취소)는 청약기간 중에만 가능(청약기간 종료일
                이후에는 청약의 철회 불가)하며, 청약기간의 종료 시 모집금액이
                발행인이 목표한 모집예정금액의 80%에 미달하는 경우 모집실패로
                간주하여 증권의 발행은 취소됩니다. 청약의 철회(취소) 시
                청약증거금은 귀하의 투자예치금계좌에 복원됩니다.
                <br/><br/>
                6. 모집의 성공 후 발행되는 모든 증권은 비상장 증권이므로
                한국거래소 상장을 목적으로 하는 것이 아니며, 증권의 환금성에 큰
                제약이 있을 수 있습니다. 귀하는 이로 인해 투자금액에 대한 일부
                또는 전부를 회수할 수 없는 위험이 있음을 이해하여야 합니다.
                <br/><br/>
                7. 귀하는 크라우드펀딩을 통해 발행된 증권이 자본시장법 제117조의10
                제7항에 따라, 전문투자자에 대한 매도 등 예외적인 경우를 제외하고는
                원칙적으로 6개월간 전매가 제한된다는 점을 이해하여야 합니다.
                <br/><br/>
                8. 귀하는 아래의 사항에 대해서도 내용을 확인하여야 합니다.
                <br/><br/>
                - (주)오마이컴퍼니는 온라인소액증권 청약과 관련하여 투자자로부터
                별도의 수수료를 징수하지 않습니다. 다만 청약증거금 용도의 자금을
                투자예치금계좌에 이체할 때 이용하는 은행의 정책에 따라 타행이체의
                경우 이체수수료가 발생할 수 있습니다.
                <br/><br/>
                - 발행인이 증권의 발행조건과 관련하여 조기상환조건을 설정하거나,
                전환조건을 설정한 경우에는 이에 대한 구체적인 내용을
                (주)오마이컴퍼니 홈페이지를 통해 반드시 확인하여야 합니다.
                <br/><br/>
                - (주)오마이컴퍼니는 자본시장법상에 따른
                "온라인소액투자중개업자"이며, 온라인소액증권발행인과
                온라인소액투자중개계약을 체결하여 위 발행인이 발행하는 증권에 대한
                청약거래를 중개하는 역할만 수행하며, 직접 증권을 발행하거나 주금을
                납입받지 않습니다.
                <br/><br/>
                - (주)오마이컴퍼니는 크라우드펀딩을 통한 자금모집을 중개하는
                온라인소액투자중개업자로서 거래의 당사자가 아니며, 투자로 인한
                손실을 보전하지 않습니다.
                <br/><br/>
                - 청약의 우대 차별 사유 : (주)오마이컴퍼니는 발행인의 요청에 따라
                법적으로 설정가능한 범위 내에서 합리적이고 명확한 기준(선착순,
                청약금액순, 전문투자자순 등)에 따라 투자자의 자격 등을 제한할 수
                있습니다. 이 경우 귀하는 위 기준과 조건에 따라 청약거래에 있어
                차별을 받게 될 수 있습니다.
                <br/><br/>
                9. 정부의 방침에 따라 크라우드펀딩 제도의 변경이 있을 수 있습니다.
                귀하는 자본시장법 등 관련법규에 근거하여 투자자의 권리에 대해 제한
                및 변경이 있는 경우 이를 준수하여야 합니다.
                <br/><br/>
                위 사항들은 (주)오마이컴퍼니를 통한 온라인소액증권 청약의
                제도∙위험 및 청약의 주문 거래와 관련하여 귀하가 알아야 할 사항을
                간략하게 서술한 것으로 거래와 관련하여 발생될 수 있는 모든 위험과
                중요 사항을 전부 기술한 것은 아닙니다. 따라서 상세한 내용은 (주)
                오마이컴퍼니 및 자본시장법상의 관계법규를 통하여 확인하시기
                바랍니다.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ctrl col-lg-3 d-none d-lg-block" ref="ctrlRef">
        <div :id="`${component.name}CtrlWrapper`" class="wrapper thin-scrollbar">
          <MakerInfo
              :invest="true"
              :builderSeq="project.openId || project.memberSeq || project.builderSeq"
              :projectType="projectType"
          />
          <ul class="tight">
            <li :class="{active:t.active}" v-for="(t, idx) in state.tabs" :key="idx">
              <button class="btn font-sm" :class="{'btn-light border-point': t.active, 'btn-light': !t.active}" @click="move(idx)">
                <span class="title" :class="{'color-point':t.active}">{{ t.title }}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, onUnmounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Number from "@/components/Number";
import MakerInfo from "@/components/MakerInfo";

function Component(initialize) {
  this.name = "pageProjectInvestorRelation";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MakerInfo, Number},
  props: {
    project: Object,
    projectSeq: Number,
    projectType: String,
    fixed: Boolean,
  },
  setup(props) {
    const component = new Component(() => {
      state.loaded = false;

      http.get(`/api/${props.projectType}/projects/${props.projectSeq}/intro`).then(({data}) => {
        state.loaded = true;
        state.investIntro = data.body.investIntro;
        state.rewards = data.body.rewardList;
        state.companyHistory = data.body.companyHistory || [];
        state.companyNews = data.body.companyNews;
        state.issuanceConditions.mains = [];
        state.issuanceConditions.sides = [[], [], []];

        state.investIntro && nextTick(() => {
          store.commit("setImagePopupListener", {
            $parents: document.querySelectorAll(`#${component.name}Content`),
            url: `/invest/${props.projectSeq}`,
            title: props.project.projectName
          });
        });

        if (data.body.issuanceCondition) {
          const conditions = data.body.issuanceCondition;

          for (let i = 0; i < conditions.length; i += 1) {
            if (i < 3) {
              if (i === 1) {
                if (["R", "Y"].includes(props.project.increaseStockCountDisplayType)) {
                  const texts = [
                    lib.getNumberFormat(lib.getNumbered(props.project.totAmount, true) + lib.getNumbered(props.project.increaseStockCount, true) * lib.getNumbered(props.project.stockAmount)),
                    "원 증액 ",
                    props.project.increaseStockCountDisplayType === "R" ? "예정" : "완료",
                  ];

                  conditions[i].tail = texts.join("");
                }
              }

              state.issuanceConditions.mains.push(conditions[i]);
            } else {
              state.issuanceConditions.sides[i % 3].push(conditions[i]);

              // for (let j = 0; j < state.issuanceConditions.sides.length; j += 1) {
              //   if (state.issuanceConditions.sides[j].length < 4) {
              //     state.issuanceConditions.sides[j].push(conditions[i]);
              //     break;
              //   }
              // }
            }
          }
        }

        state.issuanceConditions.mains.length
        && state.tabs.push({name: "issuanceCondition", title: "증권발행조건", active: false});

        props.project
        && state.tabs.push({name: "info", title: "기본정보", active: false});

        if (props.project.investPoint) {
          state.investPoints = JSON.parse(props.project.investPoint);
          state.tabs.push({name: "point", title: "투자포인트", active: false});
        }

        if (props.project.incomeDeductionFlag === "Y" && props.project.incomeDeductionCalcYn === "Y") {
          state.tabs.push({name: "incomeDeduction", title: "소득공제 혜택", active: false});
        }

        for (let i of state.investIntro) {
          state.tabs.push({name: "investIntro" + i, title: i.title, active: false});
        }

        if (props.project.rewardFlag === "Y" && Array.isArray(state.rewards) && state.rewards.length) {
          state.tabs.push({name: "reward", title: "리워드혜택", active: false});
        }

        if (state.companyHistory.length) {
          state.tabs.push({name: "corp", title: "기업소개", active: false});
        }

        state.tabs.push({
          name: "file", title: "첨부자료", active: false
        });

        state.tabs[0].active = true;

        for (let name of ["investNotiFile", "certFile", "copyFile", "articleFile", "financeFile"]) {
          Array.isArray(data.body[name]) && data.body[name].length
          && state.files.requires.push(data.body[name][0]);
        }

        state.files.references = data.body.etcFileList;
        state.files.closingDataList = data.body.closingDataList;

        Object.keys(state.files.closingDataList).length && state.tabs.push({
          name: "closingData", title: "공시자료", active: false
        });

        state.tabs.push({
          name: "warn", title: "투자 위험 고지", active: false
        });

        if (props.project && props.project.homepage && !props.project.homepage.startsWith("http")) {
          props.project.homepage = "http://" + props.project.homepage;
        }
      });
    });

    const state = reactive({
      loaded: false,
      investIntro: null,
      rewards: [],
      issuanceConditions: {
        mains: [],
        sides: [[], [], []]
      },
      investPoints: [],
      companyHistory: [],
      companyNews: [],
      tabs: [],
      calc: {
        annualIncome: 0,
        investAmount: 0,
        expectedAmount: "",
      },
      files: {
        requires: [],
        references: [],
        closingDataList: [],
      },
      isDownloading: false,
    });

    const wrapRef = ref();
    const ctrlRef = ref();

    const calc = () => {
      const annualIncome = state.calc.annualIncome;
      const investAmount = state.calc.investAmount;
      let result = 0;
      state.calc.expectedAmount = "";

      if (!annualIncome) {
        document.getElementById(`${component.name}AnnualIncome`).focus();
        return store.commit("setSwingMessage", "연 소득을 입력해주세요.");
      } else if (!investAmount) {
        document.getElementById(`${component.name}InvestAmount`).focus();
        return store.commit("setSwingMessage", "투자 금액을 입력해주세요.");
      } else if (annualIncome < investAmount) {
        document.getElementById(`${component.name}InvestAmount`).focus();
        return store.commit("setSwingMessage", "투자 금액이 연소득보다 낮아야 합니다.");
      }

      if (annualIncome > 50000000) {
        result = (annualIncome * 0.3) - (annualIncome - investAmount) * 0.3;
      } else if (annualIncome > 30000000 && annualIncome <= 50000000) {
        result = (annualIncome * 0.7) - (annualIncome - investAmount) * 0.7;
      } else if (annualIncome <= 30000000) {
        result = annualIncome - (annualIncome - investAmount);
      }

      state.calc.expectedAmount = lib.getNumberFormat(result);
    };

    const move = (idx) => {
      const part = wrapRef.value.querySelectorAll(".article .part")[idx];
      const rect = part.getBoundingClientRect();
      const contentOffsetTop = rect.top;
      const fixedTab = document.getElementById("pageProjectDetailTabs");
      const fixedBarHeight = fixedTab?.clientHeight || 0;
      const curScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      window.scrollTo({
        top: contentOffsetTop + curScrollTop - fixedBarHeight - lib.getHeaderHeight(),
        left: 0,
        behavior: "smooth"
      });
    };

    const getFileExtension = (filename) => {
      const lastDotIndex = filename.lastIndexOf(".");
      return lastDotIndex === -1 ? "" : filename.slice(lastDotIndex + 1);
    };

    const downloadAll = () => {
      if (!store.state.account.loggedIn) {
        // return store.commit("openModal", {
        //   name: "Login",
        // });

        return store.dispatch("goLoginPage");
      }

      if (state.isDownloading) {
        return store.commit("setSwingMessage", "다운로드가 이미 진행 중입니다. 잠시 기다려주세요.");
      }

      state.isDownloading = true;
      location.href = `/v2/api/invest/projects/${props.projectSeq}/file/download-all`;
      setTimeout(() => {
        state.isDownloading = false;
      }, 1500);
    };

    const previewFile = (fileSeq) => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      window.open(`/v2/api/invest/projects/${props.projectSeq}/file/${fileSeq}/preview`, "_blank");
    };

    const download = (fileSeq) => {
      if (!store.state.account.loggedIn) {
        // return store.commit("openModal", {
        //   name: "Login",
        // });

        return store.dispatch("goLoginPage");
      }

      if (state.isDownloading) {
        return store.commit("setSwingMessage", "다운로드가 이미 진행 중입니다. 잠시 기다려주세요.");
      }

      state.isDownloading = true;
      location.href = `/v2/api/invest/projects/${props.projectSeq}/file/${fileSeq}/download`;
      setTimeout(() => {
        state.isDownloading = false;
      }, 1500);
    };

    const onScroll = () => {
      const parts = wrapRef.value.querySelectorAll(".article .part");
      const scrollY = window.pageYOffset;
      const fixedBarHeight = document.getElementById("pageProjectDetailTabs")?.clientHeight || 0;
      const headerHeight = lib.getHeaderHeight();

      for (let i in parts) {
        if (parts[i] && typeof parts[i].getBoundingClientRect === "function") {
          const rect = parts[i].getBoundingClientRect();
          const start = rect.top + scrollY - fixedBarHeight - headerHeight - 1;
          const end = start + rect.height - headerHeight;

          if (scrollY > start && scrollY < end) {
            for (let j in state.tabs) {
              state.tabs[j].active = i === j;
            }
            break;
          }
        }
      }

      if (window.innerWidth > 991) {
        const $elem = document.getElementById(`${component.name}CtrlWrapper`);

        if (!$elem) {
          return;
        }

        const ctrlWrapperMaxHeight = window.innerHeight - lib.getHeaderHeight() - (document.getElementById("pageProjectDetailTabs")?.offsetHeight || 0) - 20;
        $elem.style.maxHeight = ctrlWrapperMaxHeight + "px";
      }
    };

    // const click = (e) => {
    //   if (!e.target.attributes.src || !lib.isMobile()) {
    //     return;
    //   }
    //
    //   const $contentWrap = document.querySelectorAll(`#${component.name}Content`);
    //   const $imgs = [];
    //   const paths = [];
    //
    //   for (let i = 0; i < $contentWrap?.length; i += 1) {
    //     $imgs.push(...$contentWrap[i]?.querySelectorAll("img"));
    //   }
    //
    //   for (let i = 0; i < $imgs.length; i += 1) {
    //     paths.push(i === 0 ? `?image${i}=${$imgs[i]?.attributes?.src?.value}` : `&image${i}=${$imgs[i]?.attributes?.src?.value}`);
    //   }
    //
    //   window.open(`/popup/slides${paths.join(" ")}`, "", "noopener,noreferrer");
    // };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    onUnmounted(() => {
      store.commit("setImagePopupListener", {
        $parents: document.querySelectorAll(`#${component.name}Content`),
        destroy: true
      });
    });

    return {component, state, wrapRef, ctrlRef, calc, move, getFileExtension, downloadAll, download, previewFile};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-ir {
  position: relative;

  > .row > .article {
    .part {
      padding-bottom: $px35;
      margin-bottom: $px25;

      > .wrapper {
        > .title {
          font-weight: 500;
          color: #3c3c3c;
          margin-bottom: $px20;
          padding-left: $px15;
          position: relative;

          &:before {
            content: " ";
            display: inline-block;
            width: $px3;
            height: $px22;
            position: absolute;
            top: 50%;
            margin-top: $px-11;
            left: 0;
            background: $colorPoint;
          }
        }

        > .content {
          max-width: 812px;
          margin: 0 auto;
        }
      }

      &.intro::v-deep {
        img, iframe {
          width: auto;
          height: auto;
        }
      }

      &.reward {
        padding-bottom: $px15;

        ul li {
          display: inline-block;
          padding: $px15;
          background: $colorBackground;
          border: $px1 solid #eee;
          width: $px360;
          max-width: 100%;
          margin-right: $px25;
          margin-bottom: $px25;
          vertical-align: top;
          text-align: center;

          > div {
            padding: $px5 0;

            &.title {
              font-weight: 500;
            }

            &.detail {
              //white-space: pre;
              //overflow: hidden;
              //text-overflow: ellipsis;
            }
          }
        }
      }

      &.income-deduction {
        .calc {
          input {
            height: $formHeightLg;
          }

          .btn {
            padding: $px15;
            height: $px50;
          }
        }
      }

      &.point {
        dl {
          > dt, > dd {
            display: inline;
            vertical-align: middle;
          }

          > dt {
            color: $colorAnchor;
          }

          > dd {
            font-weight: normal;
            margin: 0;
            padding-left: $px15;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:last-child {
            > dt, > dd {
              padding-bottom: 0;
            }
          }
        }
      }

      &.condition {
        .subject {
          font-size: $px14;
        }

        .value {
          font-size: $px16;
        }

        ul.main {
          margin: 0 $px-5;

          li {
            display: inline-block;
            vertical-align: top;
            min-width: $px160;
            padding: $px5;

            > .wrapper {
              background: $colorBackground;
              border: $px1 solid #eee;
              padding: $px20;
              border-radius: $px4;

              .subject {
                margin-bottom: $px5;
              }

              .tail {
                font-size: $px12;
                margin-left: $px4;
              }
            }
          }
        }

        .sides {
          .side {
            float: left;
            width: calc(100% / 3);
            margin-top: $px20;
            margin-bottom: $px-7;

            ul {
              margin-bottom: $px5;

              li {
                padding: $px8 $px15;

                .value {
                  &.font-sans {
                    font-family: Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
                  }
                }
              }
            }
          }
        }
      }

      &.base {
        dt {
          margin-bottom: $px4;
        }

        dd {
          white-space: pre-line;
          word-break: break-all;

          a {
            color: #007bff;

            &:hover {
              color: #0056b3;
            }
          }
        }
      }

      &.corp {
        .content {
          .subject {
            font-weight: 500;
          }

          .history {
            table {
              margin-top: $px10;
              border: 0;

              th, td {
                border: 0;
                padding-left: 0;
                padding-bottom: 0;
                padding-top: $px10;
              }

              th {
                width: $px60;
                white-space: nowrap;
              }

              td {
                &.subtext {
                  width: $px50;
                }
              }
            }
          }

          .news {
            padding-top: $px35;

            > .subject {
              padding-bottom: $px10;
            }

            ul {
              > li {
                .subtext {
                  margin-top: $px-1;
                }

                &:not(:last-child) {
                  padding-bottom: $px8;
                }
              }
            }
          }
        }
      }

      &.file {
        > .wrapper {
          position: relative;
        }

        .content {
          margin-top: $px25;

          .download-all {
            color: #fff;
            position: absolute;
            top: 0;
            right: 0;
          }

          ul {
            margin-top: $px15;

            li {
              margin-top: $px10;

              .file-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: $px1 solid #eee;
                padding: $px10 $px15;
              }

              .action-buttons {
                display: flex;
                gap: 10px;

                a {
                  display: block;
                  text-decoration: none;
                  text-align: left;

                  i {
                    float: right;
                    margin: $px5 0;
                  }
                }
              }
            }
          }
        }
      }

      &.warning {
        .summary {
          padding: $px20;
          background: #f8f9fa;
          border: $px1 solid #eee;
          margin-bottom: $px30;
        }
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: $px25;
      }
    }
  }

  .ctrl {
    margin-top: $px13;

    > .wrapper {
      > .profile {
        margin-bottom: $px20;
      }

      ul {
        li {
          padding-bottom: $px10;
          border-radius: $px4;
          position: relative;

          button {
            padding: $px10 $px15;
            width: 100%;
            background-color: #fff;
            text-align: left;
            position: relative;
            border: $px1 solid #eee;
            z-index: 1;

            &:hover {
              background-color: #eee;
            }
          }

          &.active {
            button {
              span {
                font-weight: 500;
              }
            }
          }

          &:first-child:before {
            top: 50%;
          }

          &:last-child {
            padding-bottom: 0;

            &:before {
              top: -50%;
            }
          }
        }
      }
    }
  }

  img {
    width: auto;
    height: auto;
  }

  @media(min-width: 992px) {
    .row > .article .part.point {
      display: table;
      width: 100%;

      dl {
        display: table-row;

        dt, dd {
          display: table-cell;
          vertical-align: top;
          padding-bottom: $px17;
        }

        dt {
          white-space: nowrap;
        }
      }
    }
  }


  @media(max-width: 991px) {
    .row > .article .part.point {
      dl {

        dt, dd {
          vertical-align: top;
          padding: 0;
        }

        dt {
          display: block;
          width: 100%;
          padding-bottom: $px2;
        }

        dd {
          padding-left: 0;
        }
      }
    }
  }

  @media(max-width: 767px) {
    > .row > .article .part {
      > .wrapper > .title {
        font-size: $px16;
        margin-bottom: $px15;
      }

      &.condition {
        .subject {
          font-size: $px14;
        }

        .value {
          font-size: $px14;
        }

        ul.main {
          li {
            width: 100%;

            > .wrapper {
              padding: $px15;

              .subject {
                margin-bottom: $px3;
              }
            }
          }
        }

        .sides {
          padding-top: $px10;

          .side {
            float: none;
            width: 100%;
            margin: 0;

            ul {
              margin: 0;

              li {
                padding: $px5 $px13;

                .subject {
                  display: inline-block;
                }

                b {
                  display: block;
                  float: right;
                }
              }
            }
          }
        }
      }

      &.base {
        > .wrapper {
          ul {
            margin-bottom: 0;

            li dl {
              display: table;
              margin-bottom: $px10;

              dt, dd {
                display: table-cell;
              }

              dt {
                width: $px90;
              }
            }
          }
        }
      }

      &.reward ul li {
        > div.detail {
          white-space: normal;
        }
      }
    }
  }

  &.fixed {
    .ctrl {
      > .wrapper {
        position: sticky;
        overflow: auto;
        top: $px167;
        right: 0;

        > ul {
          margin: 0;
        }
      }
    }
  }
}
</style>