<template>
  <div class="stick" :class="{skeleton}">
    <div class="progress">
      <div
          class="progress-bar bg-point"
          role="progressbar"
          :style="{ width: (Number(percent) > 100 ? '100' : percent) + '%' }"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
      ></div>
    </div>
    <div class="percent color-point">
      <span>{{ $lib.getNumberFormat(percent) }}%</span>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentStick";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    percent: String,
    skeleton: Boolean
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  },
});
</script>

<style lang="scss" scoped>
.stick {
  .progress {
    height: $px6;
    border-radius: $px3;
  }

  .percent {
    text-align: right;
    font-size: $px14;
    margin-top: $px5;
  }

  &.skeleton {
    .progress {
      @include skeleton;

      > div {
        display: none;
      }
    }

    .percent > span {
      @include skeleton;
      color: transparent;
    }
  }
}
</style>