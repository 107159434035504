<template>
  <div class="detail-guide">
    <div class="part">
      <Wait/>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import Wait from "@/components/Wait";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageProjectDetailGuide";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Wait},
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-guide {
  .part {
    img {
      height: $px35;
      margin-bottom: $px15;
    }

    div {
      font-size: $px16;
    }
  }
}
</style>