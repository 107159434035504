<template>
  <div class="project-detail-join-btn" :data-type="type">
    <template v-if="loaded">
      <template v-if="projectType === 'reward'">
        <button class="btn btn-point" @click="enter()" v-if="progressOrder === 1 || $lib.isPreviewPage()">
          <span class="txt">{{ mockFlag === "Y" ? "투자하기" : "후원하기" }}</span>
        </button>
        <button class="finished btn bg-point color-white" disabled v-else>
          <span class="txt">{{ project.successFailFlag === "S" ? "프로젝트 성공" : "프로젝트 마감" }}</span>
        </button>
      </template>
      <template v-else-if="projectType === 'invest'">
        <button class="btn btn-point" @click="invest('finished')" v-if="Number(project.subscribeRate) >= 80 && project.endFlag === 'Y'">
          <span class="img" style="background-image: url(/assets/ico/page.detail.main.success.svg)"></span>
          <span class="txt">투자 성공</span>
        </button>
        <button class="btn btn-point" @click="invest('finished')" v-else-if="Number(project.subscribeRate) < 80 && project.endFlag === 'Y'">
          <span class="img" style="background-image: url(/assets/ico/page.detail.main.success.svg)"></span>
          <span class="txt">투자 마감</span>
        </button>
        <button class="btn btn-point" @click="invest('preparing')" v-else-if="project.displayStateName === '펀딩준비중' || project.displayStateName === '펀딩대기중'">
          <span class="img" style="background-image: url(/assets/ico/page.detail.main.success.svg)"></span>
          <span class="txt">펀딩 대기 중</span>
        </button>
        <button class="btn bg-point color-white" @click="invest('confirm')" v-else-if="Number(project.subscribeAmount) > Number(project.totAmount) && project.endFlag === 'N'">
          <span class="txt">투자하기</span>
          <span class="img" style="background-image: url(/assets/ico/page.detail.main.join.svg)"></span>
        </button>
        <button class="btn bg-point color-white" @click="enter()" v-else>
          <span class="txt">투자하기</span>
          <span class="img" style="background-image: url(/assets/ico/page.detail.main.join.svg)"></span>
        </button>
      </template>
    </template>
    <template v-else>
      <button class="btn"></button>
    </template>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentProjectDetailJoinBtn";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    type: String,
    loaded: Boolean,
    projectType: String,
    progressOrder: Number,
    enter: Function,
    invest: Function,
    project: Object,
    mockFlag: String,
  },
  setup() {
    const component = new Component(() => {
    });

    return {
      component,
    };
  },
});
</script>

<style lang="scss" scoped>
.project-detail-join-btn {
  .btn {
    border: 0;
    position: relative;
    width: 100%;
    opacity: 1;

    .txt {
      display: inline-block;
      vertical-align: middle;
    }

    &.finished {
      cursor: default;
    }
  }

  &[data-type=main] {
    height: 100%;

    .btn {
      font-size: $px20;
      height: 100%;

      .txt {
        margin: 0 $px13;
      }
    }
  }

  &[data-type=tab] {
    padding-top: $px5;
    padding-bottom: $px5;

    .btn {
      font-size: $px20;
      height: 100%;

      .txt {
        margin: 0 $px8;
      }
    }
  }

  @media(max-width: 991px) {
    &[data-type] {
      .btn {
        font-size: $px16;
      }
    }
  }
}
</style>