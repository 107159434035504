<template>
  <div class="detail-community">
    <ul class="categories tight">
      <template v-for="(t, idx) in state.tab.list">
        <li :class="{ active: t.name === $route.query.tab }" :key="idx" v-if="t.visible">
          <router-link :to="`?tab=${t.name}`" class="btn btn-default font-sm">
            <span class="text">{{ t.title }}</span>
            <span class="count" :class="{ 'bg-point' : idx === state.tab.idx }" v-if="t.count > 0">{{ t.count }}</span>
          </router-link>
        </li>
      </template>
    </ul>
    <DetailCommunityNews :projectType="projectType" :projectSeq="projectSeq" :openId="openId" v-if="$route.query.tab === 'news'"/>
    <DetailCommunityQna :projectType="projectType" :projectSeq="projectSeq" :openId="openId" v-if="$route.query.tab === 'qna'"/>
    <DetailCommunityReview :projectType="projectType" :projectSeq="projectSeq" :openId="openId" :investEndDate="investEndDate" :investRewards="investRewards" v-if="$route.query.tab === 'review'"/>
  </div>
</template>

<script>
import {defineComponent, reactive, watch} from "@vue/composition-api";
import router from "@/scripts/router";
import DetailCommunityNews from "./DetailCommunityNews.vue";
import DetailCommunityQna from "./DetailCommunityQna.vue";
import mixin from "@/scripts/mixin";
import DetailCommunityReview from "@/pages/project/DetailCommunityReview";

function Component(initialize) {
  this.name = "pageProjectDetailCommunity";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {DetailCommunityReview, DetailCommunityNews, DetailCommunityQna},
  props: {
    projectSeq: Number,
    projectType: String,
    investEndDate: String,
    newsCount: Number,
    qnaCount: Number,
    reviewCount: Number,
    openId: String,
    investRewards: Array,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({
      tab: {
        idx: 0,
        list: [
          {name: "news", title: "최근 소식", count: props.newsCount, visible: true},
          {name: "qna", title: "Q&A", count: props.qnaCount, visible: true},
          {name: "review", title: "프로젝트 후기", count: props.reviewCount, visible: props.projectType === "reward"}
        ],
      },
    });

    const select = (tab) => {
      if (tab === "news") {
        return state.tab.idx = 0;
      } else if (tab === "qna") {
        return state.tab.idx = 1;
      } else {
        state.tab.idx = 2;
      }
    };

    const setTab = () => {
      const tab = router.app.$route.query.tab || router.app.$route.params.tab;
      select(tab);
    };

    setTab();

    watch(() => router.app.$route.query, (next, prev) => {
      if (next.modals || prev.modals) {
        return;
      }

      setTab();
    });

    return {component, state, select, setTab};
  },
})
;
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-community {
  padding-top: $px13;

  .categories {
    margin-bottom: $px20;

    li {
      background: #eee;
      display: inline-block;
      margin-right: $px5;

      a {
        border-color: #eee;
        display: table-cell;
        padding: $px6 $px12;

        span {
          vertical-align: middle;

          &.count {
            background: $colorSecondary;
            color: #fff;
            height: $px17;
            padding: 0 $px4;
            display: inline-block;
            line-height: $px16;
            font-size: $px10;
            border-radius: $px2;
            margin-left: $px5;
          }
        }

        &:hover {
          background: $colorBackground;
        }
      }

      &.active a {
        background: #fff;
      }
    }
  }

  .part {
    min-height: auto !important;
    padding-bottom: $px70 !important;

    img {
      height: $px100;
    }

    div {
      font-size: $px16;
    }
  }

  @media (max-width: 991px) {
    padding-top: $px10;

    .categories {
      margin-bottom: $px15;
    }
  }
}
</style>